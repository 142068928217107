import { Avatar, Backdrop, Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { Typography, TextField } from '@mui/material';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import * as axiosBase from 'axios';
import Logo from '../../assets/icons/Logo.svg';
import googleLogo from '../../assets/icons/logos_google-icon.svg';
import { useNavigate } from 'react-router-dom';

const axios = axiosBase.create({
	headers: {
		Accept: 'application/json',
		'Access-Control-Allow-Origin': '*'
	}
});

const NewLogin = () => {
	const [loading, setLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [code, setCode] = useState('');
	const [errroMessagem, setErrorMessage] = useState('');
	const [errors, setErrors] = useState({});
	const [message, setMessage] = useState('');
	const navigate = useNavigate();
	const [openCodeVerification, setOpenCodeVerification] = useState(false);

	const handleSubmit = e => {
		e.preventDefault();
		setLoading(true);
		setErrors([]);

		axios
			.post(
				`${process.env.REACT_APP_LARAVEL_API_URL}/login`,
				{
					email: email,
					password: password
				},
				{}
			)
			.then(res => {
				if (res.data.success === true) {
					localStorage.setItem('access_token', res.data.token);
					// localStorage.setItem('user', JSON.stringify(res.data.user));
					if (res.data.two_factor_enabled) {
						setOpenCodeVerification(true);
					} else {
						localStorage.setItem('user', JSON.stringify(res.data.user));
						navigate('/enable-2fa');
					}
				} else {
					setMessage(res.data.message);
				}
				setLoading(false);
			})
			.catch(error => {
				if (error.response.status === 422) {
					setErrors(error.response.data.errors);
					setLoading(false);
				}
			});
	};
	const on2FAVerify = async ev => {
		const accessToken = localStorage.getItem('access_token');
		setErrorMessage('');
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_LARAVEL_API_URL}/2fa/totp`,
				{
					code
				},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			);

			if (response.status === 200) {
				localStorage.setItem('access_token', response.data.data.token);
				localStorage.setItem('user', JSON.stringify(response.data.data.user));
				window.location.href = '/chopping-interface';
			}
		} catch (error) {
			setErrorMessage('Incorrect code, please try again!');
		}
	};

	const inputFieldIconStyle = {
		color: 'white',
		fontSize: 20
	};

	return (
		<Grid
			container
			component='main'
			style={{
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				background: 'black'
			}}
			sx={{
				background: '#282828',
				color: 'white'
			}}
		>
			{!openCodeVerification && (
				<Grid item xs={12} sm={8} elevation={6} square>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							color: 'white'
							// background: '#282828'
						}}
					>
						<>
							<img src={Logo} alt='redwing log' style={{ margin: '0rem 0 5rem 0' }} />
							<Typography style={{ fontSize: '25px', color: 'white', fontWeight: '400' }}>
								Welcome Back
							</Typography>
							<Typography
								style={{
									fontSize: '16px',
									color: 'white',
									marginBottom: '2rem',
									fontWeight: '400'
								}}
							>
								Please Enter Your Details !
							</Typography>
							{message && <div style={{ color: 'red' }}> {message}</div>}
							<Box
								component='form'
								noValidate
								sx={{
									mt: 1,
									'& .MuiInputBase-root': {
										color: 'white !important',
										background: '#282828 !important'
									},

									'& .MuiInputLabel-outlined ': {
										color: 'white !important'
									},
									'& .MuiFormLabel-filled': {
										background: '#282828 !important'
									}
								}}
								onSubmit={e => {
									handleSubmit(e);
								}}
							>
								<TextField
									fullWidth
									variant='outlined'
									id='email'
									label='Email'
									name='email'
									autoComplete='email'
									autoFocus
									style={{
										color: 'white'
									}}
									sx={{
										background: '#282828 !important',
										color: '#ffffff',
										'& .MuiInputBase-input': {
											background: '#282828 !important',
											color: 'white !important'
										},
										'& .MuiInputBase-root': {
											background: '#282828 !important',
											color: 'white !important'
										},
										'& .MuiFormLabel-filled': {
											background: '#282828 !important'
										}
									}}
									value={email}
									onChange={e => {
										setEmail(e.target.value);
									}}
									InputProps={{
										endAdornment: (
											<InputAdornment position='start'>
												<MailOutlineIcon sx={inputFieldIconStyle} />
											</InputAdornment>
										)
									}}
									error={errors.email ? true : false}
									helperText={errors.email?.toString()}
								/>

								<div>
									<TextField
										margin='normal'
										fullWidth
										variant='outlined'
										name='password'
										label='Password'
										id='password'
										autoComplete='current-password'
										type={showPassword ? 'text' : 'password'}
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton
														aria-label='toggle password visibility'
														onClick={() => setShowPassword(!showPassword)}
														// onMouseDown={handleMouseDownPassword}
													>
														{showPassword ? (
															<VisibilityOff sx={inputFieldIconStyle} />
														) : (
															<Visibility sx={inputFieldIconStyle} />
														)}
													</IconButton>
												</InputAdornment>
											)
										}}
										error={errors.password ? true : false}
										helperText={errors.password?.toString()}
										onChange={e => {
											setPassword(e.target.value);
										}}
									/>
									<p style={{ fontSize: '14px', textAlign: 'right', margin: '0', padding: '0' }}>
										Forgot Password ?
									</p>
								</div>

								<Button
									type='submit'
									fullWidth
									variant='contained'
									style={{
										marginTop: 16,
										margionBottom: 2,
										background: '#436ACD',
										color: 'white',
										fontSize: '16px',
										textTransform: 'none'
									}}
								>
									Sign In
								</Button>

								<a
									href={`${process.env.REACT_APP_LARAVEL_API_URL}/google/login`}
									style={{ fontSize: '16px' }}
								>
									<Button
										type='button'
										fullWidth
										variant='contained'
										style={{
											marginTop: 8,
											margionBottom: 2,
											background: '#282828',
											fontSize: '16px',
											color: 'white',
											textTransform: 'none'
										}}
									>
										<img
											src={googleLogo}
											alt='google logo'
											style={{ width: '8%', margin: '0 1rem' }}
										/>
										Sign In With Google
									</Button>
								</a>
								<p style={{ fontSize: '14px', textAlign: 'center' }}>
									Don’t Have An Account?
									<span
										style={{
											color: '#436ACD',
											fontWeight: '600',
											margin: '0 0.5rem'
										}}
									>
										Sign Up
									</span>
								</p>
							</Box>
						</>
					</Box>
				</Grid>
			)}

			{openCodeVerification && (
				<Grid xs={12} sm={4}>
					<>
						<Typography
							style={{
								fontSize: '25px',
								color: 'white',
								marginBottom: '1rem',
								fontWeight: '400'
							}}
							textAlign={'center'}
						>
							Verify your two-factor authentication (2FA)
						</Typography>
						<Typography
							style={{
								fontSize: '14px',
								color: 'white',
								marginBottom: '2rem',
								fontWeight: '400'
							}}
							textAlign={'center'}
						>
							Please provide the authentication code from your <b>Google Authenticator</b>
						</Typography>

						<TextField
							fullWidth
							variant='outlined'
							id='auth-code'
							name='code'
							autoFocus
							sx={{
								background: '#282828 !important',
								color: 'white',
								'& .MuiInputBase-input': {
									background: '#282828 !important',
									color: 'white !important',
									fontSize: '13px'
								},
								'& .MuiInputBase-root': {
									background: '#282828 !important'
								},
								'& .MuiFormLabel-filled': {
									background: '#282828 !important'
								}
							}}
							onChange={ev => setCode(ev.target.value)}
						/>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							style={{
								marginTop: 16,
								margionBottom: 2,
								background: '#436ACD',
								color: 'white',
								fontSize: '16px',
								textTransform: 'capitalize'
							}}
							onClick={on2FAVerify}
						>
							Verify
						</Button>
					</>
				</Grid>
			)}

			<Backdrop open={loading} style={{ zIndex: 10000 }}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</Grid>
	);
};

export default NewLogin;
