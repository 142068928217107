import React, { useState, useRef, useEffect, useCallback } from 'react';
import GlobalStyle from 'globalStyles';
import useData from 'hooks/use-data';
import { AllDataContext } from 'context/AllDataContext';
import { DraggingContext } from 'context/isDragging';
// screens
import Kanban from 'screens/Kanban/Kanban';
import RapidEstimation from 'screens/RapidEstimation/RapidEstimation';
import Needs from 'screens/Drive/Drive';
import Home from 'screens/Home/Home';
import NewHome from 'screens/Home/NewHome';
// routing
import { Route, useLocation, Routes, Navigate } from 'react-router-dom';
import TimeBar from 'components/TimeBar/TimeBar';
import FocusMode from 'components/FocusMode/FocusMode';
import BasecampLoginCallback from 'components/Login/BasecampLoginCallback';
import TeamWork from 'screens/TeamWork/TeamWork';
import BigDashboard from 'screens/BigDashboard';
import Header from './components/Header/Header';

import OneSignal from 'react-onesignal';
import Segments from 'screens/Segments/Segments';
import OnboardUser from 'components/OnboardUser';
import NewDashboard from 'screens/new-dashboard/NewDashboard';
import { GetData, HandleSave } from 'db/Connect';
import ReportDocumentation from 'screens/ReportDocumentation';
import Performance from 'screens/Performance/Performance';
import PerformanceFeedback from 'screens/Performance/PerformanceFeedback';
import PerformanceFeedbackReport from 'screens/Performance/PerformanceFeedbackReport';
import LeaveForm from 'components/Forms/LeaveForm';
import PerformanceUser from 'screens/Performance/PerformanceUser';
import PerformanceTeamLead from 'screens/Performance/PerformanceTeamLead';
import LeaveApplication from 'screens/LeaveApplications/LeaveApplication';
import TodoPage from 'screens/TodoList/TodoPage';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import styles from '../src/components/NavigationBar/NavigationBar.module.css';
import './assets/css/chopping-interface.css';
import ChoppingInterface from 'screens/ChoppingInterface/ChoppingInterface';
import ChoppingDashboard from 'screens/ChoppingDashboard/ChoppingDashboard';
import UserTaskList from 'screens/UserTaskList/UserTaskList';
import Team from 'screens/Team/Team';
import Login from 'screens/Login/Login';
import PasswordSetup from 'screens/Login/PasswordSetup';
import LoginCallback from 'screens/Login/LoginCallback';
import useAuth from 'hooks/useAuth';
import UserCompletionList from 'screens/UserCompletionList/UserCompletionList';
import Notifications from 'screens/Notifications/Notifications';
import UserUnplannedTasks from 'screens/UserUnplannedTasks/UserUnplannedTasks';
import NewLogin from 'screens/Login/NewLogin';
import TaskDetails from 'screens/ChoppingInterface/TaskDetails';
import User from 'screens/User/User';
import EscalatedTask from 'screens/EscalatedTask/EscalatedTask';
import WorksheetActivity from 'screens/WorksheetActivity/WorksheetActivity';
import WorksheetCompletionList from 'screens/WorksheetCompletionList/WorksheetCompletionList';
import UserUnassignedTasks from 'screens/UserUnplannedTasks/UserUnassignedTasks';

import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import reducer from 'reducers/presetMessages';
import reducerTasks from 'reducers/presetTasks';
import searchIndexReducer from 'reducers/searchIndex';
import thunk from 'redux-thunk';
import PresetMessages from 'screens/PresetMessages/PresetMessages';
import PresetTasks from 'screens/PresetTasks/PresetTasks';
import { DeliverySheet } from 'screens/Delivery Sheet/deliverySheet';
import { GlobalSearch } from 'screens/GlobalSearch/GlobalSearch';
import { PublicComment } from 'screens/PublicComment/publicComment';
import Deliveries from 'screens/Deliveries/Deliveries';
import Timesheet from 'screens/Timesheet';
import { TwoFAStart } from 'screens/2FA/2FAStart';

const rootReducer = combineReducers({
	reducer,
	reducerTasks,
	searchIndexReducer
});
const store = createStore(rootReducer, applyMiddleware(thunk));

// >>>>>>> 933eca3fc8c0a4cfd7d53cc58a8691853a89cd6b

function RequireAuth({ children, isUserLoggedIn }) {
	return isUserLoggedIn === true ? children : <Navigate to='/login' replace />;
}

function RequireOwner({ children }) {
	const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};

	return user?.role === 'owner' ? children : <></>;
}

const App = () => {
	const token = localStorage.getItem('set_password_token');
	const [isBottomNavVisible, setBottomNavVisible] = useState(true);

	const [globalState, setGlobalState] = useState({
		tasks: {},
		activities: {},
		otherWorks: {},
		workList: [],
		allPainPoints: {},
		columns: {},
		rows: {},
		grid: [],
		projectOrder: [],
		activityOrder: [],
		workOrder: [],
		painPointOrder: [],
		columnOrder: [],
		rowOrder: []
	});
	const firstTimeRef = useRef(true);
	const [selectedProject, setSelectedProject] = useState(null);
	let data = GetData();
	useEffect(() => {
		if (data.length !== 0 || data !== null || data !== undefined) {
			if (data.length !== 0) {
				setGlobalState(data[0]);
			}
		}
	}, [data]);

	const [showSegments, setShowSegments] = useState(false);
	const [teamWorkFullView, setTeamWorkFullView] = useState(false);
	const { isUserLoggedIn } = useAuth();

	useEffect(() => {
		OneSignal.init({
			appId: '7da97ba1-bdf1-4a39-86a1-2d16e97c85a3',
			allowLocalhostAsSecureOrigin: true
		}).then(() => {
			OneSignal.Slidedown.promptPush();
			if (isUserLoggedIn) {
				const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
				OneSignal.login(user._id);
			}
		});
	}, []);

	const calculateTimerForGlobe = useCallback(
		activities => {
			let t = {
				day: 0,
				evening: 0
			};
			globalState?.projectOrder.forEach(projectID => {
				const project = globalState.tasks[projectID];
				project.activityList.forEach(a => {
					const activity = activities[a.id];
					if (a.isSelected) {
						if (project.isEveningTask) {
							t.evening += activity.time;
						} else if (activity.evening) {
							t.evening += activity.time;
						} else {
							t.day += activity.time;
						}
						//project.isEveningTask ? (t.evening += activity.time) : (t.day += activity.time);
					}
				});
			});

			globalState?.workList.forEach(a => {
				const work = globalState?.otherWorks[a.id];
				if (a.isSelected) {
					t.day += work.time;
				}
			});

			return t;
		},
		[globalState?.projectOrder, globalState?.tasks, globalState]
	);

	const calculateTimerForIndividualProjects = project => {
		if (!project) return 0;
		let t = 0;
		project.activityList.forEach(a => {
			const activity = globalState.activities[a.id];
			if (a.isSelected) {
				t += activity.time;
			}
		});
		return t;
	};
	const updateCompleteStatus = globalTask => {
		const tasks = {};
		Object.values(globalTask).forEach(project => {
			const timing = calculateTimerForIndividualProjects(project);
			tasks[project.id] = {
				...project,
				activityList: [...project.activityList],
				isCompleted: timing === 0
			};
		});
		setGlobalState(old => {
			const newData = { ...old, tasks };
			return newData;
		});
		firstTimeRef.current = true;
	};

	useEffect(() => {
		if (globalState && globalState.tasks && firstTimeRef.current) {
			updateCompleteStatus(globalState.tasks);
			firstTimeRef.current = false;
		}

		// eslint-disable-next-line
	}, [globalState?.tasks]);

	const [timer, setTimer] = useState(() => calculateTimerForGlobe(globalState?.activities));
	const [isDragging, setIsDragging] = useState(false);
	const [isInverted, setIsInverted] = useState(false);
	const [open, setOpen] = useState(false);
	const [isWorksheetSidebarHidden, setIsWorksheetSidebarHidden] = useState(() => {
		return JSON.parse(localStorage.getItem('isFullScreen')) || false;
	});

	const [alertCount, setAlertCount] = useState(0);
	const [notificationCount, setNotificationCount] = useState(0);

	const toggleWorksheetSidebar = () => {
		setIsWorksheetSidebarHidden(prev => !prev);
	};
	useEffect(() => {
		localStorage.setItem('isFullScreen', isWorksheetSidebarHidden);
	}, [isWorksheetSidebarHidden]);

	// const previousXPosition = useRef(null);
	const location = useLocation();

	var screenName = 'Activities';
	if (location.state) {
		screenName = location.state.tab;
	}

	useEffect(() => {
		setTimer(t => (t = calculateTimerForGlobe(globalState?.activities)));
	}, [calculateTimerForGlobe, globalState?.activities, globalState]);

	const SHOW_TIMER =
		window.location.pathname !== '/' &&
		window.location.pathname == 'enable-2fa' &&
		window.location.pathname !== '/homepage' &&
		window.location.pathname !== '/onboard_user' &&
		window.location.pathname !== '/chopping-dashboard' &&
		window.location.pathname !== '/timesheet' &&
		window.location.pathname !== '/tasks/assigned' &&
		window.location.pathname !== '/team' &&
		!window.location.pathname.includes('/password/setup') &&
		!window.location.pathname.includes('tasks/unestimated') &&
		!window.location.pathname.includes('user') &&
		!window.location.pathname.includes('deliveries') &&
		!window.location.pathname.includes('comment') &&
		window.location.pathname !== '/task/escalated' &&
		window.location.pathname !== '/tasks/unassigned' &&
		window.location.pathname !== '/task/escalated' &&
		window.location.pathname !== '/new-login' &&
		window.location.pathname !== '/notifications' &&
		window.location.pathname !== '/search' &&
		window.location.pathname !== '/delivery-list' &&
		window.location.pathname !== '/preset-messages' &&
		window.location.pathname !== '/preset-tasks';
	if (globalState === null || globalState === undefined) {
		return null;
	}

	return (
		<div style={{ position: 'relative' }}>
			{window.location.pathname !== '/login' &&
				window.location.pathname !== '/enable-2fa' &&
				!window.location.pathname.includes('deliveries') &&
				!window.location.pathname.includes('comment') && (
					<NavigationBar
						showSegments={showSegments}
						setShowSegments={setShowSegments}
						alertCount={alertCount}
						setAlertCount={setAlertCount}
						teamWorkFullView={teamWorkFullView}
						setTeamWorkFullView={setTeamWorkFullView}
						notificationCount={notificationCount}
						setNotificationCount={setNotificationCount}
						setBottomNavVisible={setBottomNavVisible}
						isBottomNavVisible={isBottomNavVisible}
					/>
				)}
			<div
				className={
					window.location.pathname !== '/login' &&
					window.location.pathname !== '/enable-2fa' &&
					!window.location.pathname.includes('deliveries')
						? styles.main
						: {}
				}
			>
				<AllDataContext.Provider value={{ globalState, setGlobalState }}>
					<DraggingContext.Provider value={{ isDragging, setIsDragging }}>
						{window.location.pathname !== '/onboard_user' && <GlobalStyle />}
						{/* && window.location.pathname !== '/login' */}

						{SHOW_TIMER && <TimeBar timer={timer} />}

						{window.location.pathname !== '/' && window.location.pathname !== '/onboard_user' ? (
							<Header setOpenFocusMode={setOpen} selectedProject={selectedProject} />
						) : null}

						<Provider store={store}>
							<Routes location={location} key={location.pathname}>
								<Route
									exact
									path='/kanban'
									element={
										<Kanban
											screenIndex={0}
											setIsDragging={setIsDragging}
											isInverted={isInverted}
											setOpenFocusMode={setOpen}
										/>
									}
								/>
								<Route
									exact
									path='/rapid-estimation'
									element={
										<RapidEstimation
											screenIndex={1}
											setOpenFocusMode={setOpen}
											isInverted={isInverted}
											setSelectedProject={setSelectedProject}
											selectedProject={selectedProject}
											tabValues={screenName}
											timerr={timer}
										/>
									}
								/>
								<Route
									exact
									path='/team-work'
									element={<TeamWork screenIndex={2} isInverted={isInverted} />}
								/>
								<Route
									exact
									path='/drive'
									element={<Needs screenIndex={2} isInverted={isInverted} />}
								/>
								<Route exact path='/auth/callback' element={<BasecampLoginCallback />} />
								<Route exact path='/homepage' element={<NewHome />} />
								<Route exact path='/newhome' element={<Home />} />
								<Route
									exact
									path='/old-dashboard'
									element={
										<BigDashboard
											globalState={globalState}
											setSelectedProject={setSelectedProject}
											selectedProject={selectedProject}
											timer={timer}
										/>
									}
								/>
								<Route path='/deliveries'>
									<Route path=':name' element={<DeliverySheet />} />
								</Route>
								<Route path='/comment'>
									<Route path=':id' element={<PublicComment />} />
								</Route>
								<Route
									exact
									path='/timesheet'
									element={
										<RequireAuth isUserLoggedIn={isUserLoggedIn}>
											<Timesheet
												globalState={globalState}
												setGlobalState={setGlobalState}
												setSelectedProject={setSelectedProject}
												selectedProject={selectedProject}
												timerr={timer}
												showSegments={false}
												teamWorkFullView={true}
											/>
										</RequireAuth>
									}
								/>
								<Route
									exact
									path='/chopping-dashboard'
									element={
										<RequireAuth isUserLoggedIn={isUserLoggedIn}>
											<ChoppingDashboard
												globalState={globalState}
												setGlobalState={setGlobalState}
												setSelectedProject={setSelectedProject}
												selectedProject={selectedProject}
												timerr={timer}
												showSegments={false}
												teamWorkFullView={true}
											/>
										</RequireAuth>
									}
								/>
								<Route
									exact
									path='/'
									element={
										<RequireAuth isUserLoggedIn={isUserLoggedIn}>
											<ChoppingDashboard
												globalState={globalState}
												setGlobalState={setGlobalState}
												setSelectedProject={setSelectedProject}
												selectedProject={selectedProject}
												timerr={timer}
												showSegments={false}
												teamWorkFullView={true}
											/>
										</RequireAuth>
									}
								/>
								<Route
									exact
									path='/segments'
									element={<Segments alertCount={alertCount} setAlertCount={setAlertCount} />}
								/>
								<Route exact path='/onboard_user' element={<OnboardUser />} />
								<Route
									exact
									path='/performance_report_documentation'
									element={<ReportDocumentation />}
								/>
								<Route exact path='/performance' element={<Performance />} />
								<Route exact path='/performance/user' element={<PerformanceUser />} />
								<Route exact path='/performance/feedback' element={<PerformanceFeedback />} />
								<Route
									exact
									path='/performance/team_lead_report'
									element={<PerformanceTeamLead />}
								/>
								<Route
									exact
									path='/leave'
									element={
										<RequireAuth isUserLoggedIn={isUserLoggedIn}>
											{' '}
											<LeaveForm />{' '}
										</RequireAuth>
									}
								/>
								<Route
									exact
									path='/leave/report'
									element={
										<RequireAuth isUserLoggedIn={isUserLoggedIn}>
											{' '}
											<LeaveApplication />{' '}
										</RequireAuth>
									}
								/>
								<Route exact path='/todo' element={<TodoPage />} />
								<Route
									exact
									path='/performance/feedback_report'
									element={<PerformanceFeedbackReport />}
								/>
								<Route path='/chopping-interface'>
									<Route
										index
										element={
											<RequireAuth isUserLoggedIn={isUserLoggedIn}>
												<ChoppingInterface
													notificationCount={notificationCount}
													setBottomNavVisible={setBottomNavVisible}
													isBottomNavVisible={isBottomNavVisible}
													onToggleWorksheetSidebar={toggleWorksheetSidebar}
													isWorksheetSidebarHidden={isWorksheetSidebarHidden}
												/>
											</RequireAuth>
										}
									/>
									<Route
										path=':worksheet_id'
										element={
											<RequireAuth isUserLoggedIn={isUserLoggedIn}>
												<ChoppingInterface
													notificationCount={notificationCount}
													setBottomNavVisible={setBottomNavVisible}
													isBottomNavVisible={isBottomNavVisible}
													onToggleWorksheetSidebar={toggleWorksheetSidebar}
													isWorksheetSidebarHidden={isWorksheetSidebarHidden}
												/>
											</RequireAuth>
										}
									/>
									<Route
										path=':worksheet_id/activity'
										element={
											<RequireAuth isUserLoggedIn={isUserLoggedIn}>
												<WorksheetActivity notificationCount={notificationCount} />
											</RequireAuth>
										}
									/>

									<Route
										path=':worksheet_id/completion/today'
										element={
											<RequireAuth isUserLoggedIn={isUserLoggedIn}>
												<WorksheetCompletionList notificationCount={notificationCount} />
											</RequireAuth>
										}
									/>
									<Route
										path='task/:task_id'
										element={
											<RequireAuth isUserLoggedIn={isUserLoggedIn}>
												<TaskDetails />
											</RequireAuth>
										}
									/>
								</Route>

								<Route path='/tasks/assigned'>
									<Route
										index
										element={
											<RequireAuth isUserLoggedIn={isUserLoggedIn}>
												{' '}
												<UserTaskList />{' '}
											</RequireAuth>
										}
									/>
									<Route
										path=':user_id'
										element={
											<RequireAuth isUserLoggedIn={isUserLoggedIn}>
												{' '}
												<UserTaskList />{' '}
											</RequireAuth>
										}
									/>
								</Route>
								<Route path='/tasks/unestimated'>
									<Route
										index
										element={
											<RequireAuth isUserLoggedIn={isUserLoggedIn}>
												<UserUnplannedTasks />
											</RequireAuth>
										}
									/>

									<Route
										path=':user_id'
										element={
											<RequireAuth isUserLoggedIn={isUserLoggedIn}>
												<UserUnplannedTasks />
											</RequireAuth>
										}
									/>
								</Route>
								<Route
									path='/tasks/unassigned'
									element={
										<RequireAuth isUserLoggedIn={isUserLoggedIn}>
											<UserUnassignedTasks />
										</RequireAuth>
									}
								></Route>
								<Route
									path='/team'
									element={
										<RequireAuth isUserLoggedIn={isUserLoggedIn}>
											<Team />
										</RequireAuth>
									}
								/>
								<Route path='/completion'>
									<Route
										index
										element={
											<RequireAuth isUserLoggedIn={isUserLoggedIn}>
												{' '}
												<UserCompletionList />{' '}
											</RequireAuth>
										}
									/>
									<Route
										path=':user_id'
										element={
											<RequireAuth isUserLoggedIn={isUserLoggedIn}>
												{' '}
												<UserCompletionList />{' '}
											</RequireAuth>
										}
									/>
								</Route>
								<Route
									exact
									path='/task/escalated'
									element={
										<RequireAuth isUserLoggedIn={isUserLoggedIn}>
											<EscalatedTask />
										</RequireAuth>
									}
								/>
								<Route path='/login' element={<NewLogin />} />
								<Route path='/enable-2fa' element={<TwoFAStart />} />
								{/* <Route path='/new_login' element={<NewLogin />} /> */}
								<Route exact path='/login/callback' element={<LoginCallback />} />
								<Route
									exact
									path='/notifications'
									element={<Notifications setNotificationsCount={setNotificationCount} />}
								/>
								<Route
									exact
									path='/search'
									element={
										<RequireAuth isUserLoggedIn={isUserLoggedIn}>
											<GlobalSearch origin={'page'} />
										</RequireAuth>
									}
								/>
								<Route
									exact
									path='/delivery-list'
									element={
										<RequireAuth isUserLoggedIn={isUserLoggedIn}>
											<Deliveries />
										</RequireAuth>
									}
								/>

								<Route
									path='/password/setup/:token/:email'
									element={
										<RequireAuth isUserLoggedIn={isUserLoggedIn}>
											<PasswordSetup />
										</RequireAuth>
									}
								/>

								<Route
									path='/user'
									element={
										<RequireAuth isUserLoggedIn={isUserLoggedIn}>
											<User />
										</RequireAuth>
									}
								/>

								<Route
									path='/preset-messages'
									element={
										<RequireAuth isUserLoggedIn={isUserLoggedIn}>
											<RequireOwner>
												<PresetMessages />
											</RequireOwner>
										</RequireAuth>
									}
								/>

								<Route
									path='/preset-tasks'
									element={
										<RequireAuth isUserLoggedIn={isUserLoggedIn}>
											<RequireOwner>
												<PresetTasks />
											</RequireOwner>
										</RequireAuth>
									}
								/>
								<Route
									path=':user_id'
									element={
										<RequireAuth isUserLoggedIn={isUserLoggedIn}>
											{' '}
											<UserCompletionList />{' '}
										</RequireAuth>
									}
								/>
								<Route
									exact
									path='/task/escalated'
									element={
										<RequireAuth isUserLoggedIn={isUserLoggedIn}>
											<EscalatedTask />
										</RequireAuth>
									}
								/>
								<Route path='/login' element={<NewLogin />} />
								{/* <Route path='/new-login' element={<NewLogin />} /> */}
								<Route exact path='/login/callback' element={<LoginCallback />} />
								<Route
									exact
									path='/notifications'
									element={<Notifications setNotificationsCount={setNotificationCount} />}
								/>

								<Route
									path='/password/setup/:token/:email'
									element={
										<RequireAuth isUserLoggedIn={isUserLoggedIn}>
											<PasswordSetup />
										</RequireAuth>
									}
								/>
							</Routes>
						</Provider>
						<FocusMode open={open} setOpen={setOpen} />
					</DraggingContext.Provider>
				</AllDataContext.Provider>
			</div>
		</div>
	);
};

export default App;
