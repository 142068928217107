import api from './customAxios';

const API_ENDPOINTS = {
	FETCH_WORKSHEET_TASKS: '/task',
	FETCH_TASKS_BY_IDS: '/task/bulkFetch',
	FETCH_TASKS_WITH_NEW_COMMENTS: '/task/has-new-comments',
	FETCH_WORKSHEETS: '/worksheet',
	FETCH_TASK_COMMENTS: '/comment',
	FETCH_USERS: '/users'
};

/**
 * @param {Object} params
 * @param {boolean} params.refresh When true, it will get the latest data from DB instead of cache
 */
export const fetchUsers = async (params = {}) => {
	const { refresh = false } = params;
	return api.get(API_ENDPOINTS.FETCH_USERS, {
		params: {
			refresh
		}
	});
};

/**
 *
 * @param {Array} taskIds list of task ids to bulk fetch data
 */
export const fetchTaskDetailsInBulk = async (taskIds = []) => {
	return api.post(API_ENDPOINTS.FETCH_TASKS_BY_IDS, {
		task_ids: taskIds
	});
};

/**
 *
 * @param {Object} params
 * @param {boolean} params.refresh When true, it will get the latest data from DB instead of cache
 */
export const fetchWorkSheetList = async (params = {}) => {
	const { refresh = false } = params;
	return api.get(`${API_ENDPOINTS.FETCH_WORKSHEETS}`, {
		params: {
			refresh
		}
	});
};

/**
 *
 * @param {Object} params
 * @param {boolean} params.refresh When true, it will get the latest data from DB instead of cache
 */
export const fetchWorkSheetTasks = async (worksheetId, params = {}) => {
	const { refresh = false } = params;
	return api.get(`${API_ENDPOINTS.FETCH_WORKSHEET_TASKS}`, {
		params: {
			worksheet_id: worksheetId,
			refresh
		}
	});
};

/**
 *
 * @param {Object} params
 * @param {boolean} params.refresh When true, it will get the latest data from DB instead of cache
 */
export const fetchTaskDetails = async (taskId, params = {}) => {
	const { refresh = false } = params;
	return api.get(`${API_ENDPOINTS.FETCH_WORKSHEET_TASKS}/${taskId}`, {
		params: {
			refresh
		}
	});
};

/**
 *
 * @param {Object} params
 * @param {boolean} params.refresh When true, it will get the latest data from DB instead of cache
 */
export const getTaskComments = async (taskId, params = {}) => {
	const { refresh = false } = params;
	return api.get(`${API_ENDPOINTS.FETCH_WORKSHEET_TASKS}/${taskId}`, {
		params: {
			refresh
		}
	});
};

export const fetchTasksWithNewComments = async (params = {}) => {
	return api.get(`${API_ENDPOINTS.FETCH_TASKS_WITH_NEW_COMMENTS}`, {
		params
	});
};

export const updateEstimate = async (taskId, estimate) => {
	return api.post(`/task/${taskId}/estimate`, { estimate: estimate });
};
