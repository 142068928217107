import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { TextField, Typography, Box, Stack } from '@mui/material';

import QRCode from 'qrcode';
import * as axiosBase from 'axios';

const axios = axiosBase.create({
	headers: {
		Accept: 'application/json',

		'Access-Control-Allow-Origin': '*'
	}
});
export function TwoFAStart() {
	const [authData, setAuthData] = useState();
	const [qrCodeImage, setQrCodeImage] = useState();
	const [open, setOpen] = useState(false);
	const [code, setCode] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const on2FAEnable = async ev => {
		ev.preventDefault();
		const accessToken = localStorage.getItem('access_token');
		const response = await axios.post(`${process.env.REACT_APP_LARAVEL_API_URL}/2fa/enable`, null, {
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		});

		setAuthData(response.data.data);
		QRCode.toDataURL(response.data.data.qr_code_url, (err, imageData) => {
			setQrCodeImage(imageData);
		});
		setOpen(true);
	};
	const on2FAVerify = async ev => {
		const accessToken = localStorage.getItem('access_token');
		setErrorMessage('');
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_LARAVEL_API_URL}/2fa/totp`,
				{
					code
				},
				{
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			);
			if (response.status === 200) {
				localStorage.setItem('access_token', response.data.data.token);
				localStorage.setItem('user', JSON.stringify(response.data.data.user));
				window.location.href = '/chopping-interface';
			}
		} catch (error) {
			setErrorMessage('Incorrect code, please try again!');
		}
	};

	return (
		<div>
			<Grid
				container
				component='main'
				style={{
					height: '100vh',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					background: 'black'
				}}
				sx={{
					background: '#282828',
					color: 'white'
				}}
			>
				{!open && (
					<Grid item xs={12} sm={8} elevation={6} square>
						<Box
							sx={{
								my: 8,
								mx: 4,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								color: 'white'
								// background: '#282828'
							}}
						>
							<Typography
								style={{
									fontSize: '25px',
									color: 'white',
									fontWeight: '400',
									textTransform: 'capitalize'
								}}
							>
								two factor authentication
							</Typography>
							<Typography
								style={{
									fontSize: '16px',
									color: 'white',
									marginBottom: '2rem',
									fontWeight: '400',
									textTransform: 'capitalize'
								}}
							>
								secure your account with two-factor authentication
							</Typography>

							<Box
								component='form'
								noValidate
								sx={{
									mt: 1,
									'& .MuiInputBase-root': {
										color: 'white !important',
										background: '#282828 !important'
									},

									'& .MuiInputLabel-outlined ': {
										color: 'white !important'
									},
									'& .MuiFormLabel-filled': {
										background: '#282828 !important'
									}
								}}
							>
								<Button
									type='submit'
									fullWidth
									variant='contained'
									style={{
										marginTop: 16,
										margionBottom: 2,
										background: '#436ACD',
										color: 'white',
										fontSize: '16px',
										textTransform: 'capitalize'
									}}
									onClick={ev => on2FAEnable(ev)}
								>
									Enable Two Factor Authentication
								</Button>
								<Button
									type='button'
									fullWidth
									onClick={() => {
										window.location.href = '/chopping-interface';
									}}
									variant='contained'
									style={{
										marginTop: 8,
										margionBottom: 2,
										background: '#282828',
										fontSize: '16px',
										color: 'white',
										// textTransform: 'none',
										textTransform: 'capitalize'
									}}
								>
									Skip this step
								</Button>
							</Box>
						</Box>
					</Grid>
				)}
				{open && (
					<Grid item xs={12} sm={4} elevation={3}>
						<Box
							sx={{
								my: 8,
								mx: 4,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								color: 'white'
								// background: '#282828'
							}}
						>
							<Typography
								style={{
									fontSize: '25px',
									color: 'white',
									fontWeight: '400',
									textTransform: 'capitalize'
								}}
							>
								Authenticator
							</Typography>
							<Typography
								style={{
									fontSize: '16px',
									color: 'white',
									marginBottom: '2rem',
									fontWeight: '400'
								}}
								textAlign={'center'}
							>
								Use a authenticator app like <b>Google Authenticator</b> to scan this QR code. It
								will generate a code for you to enter below
							</Typography>
							<img src={qrCodeImage} alt='qr-code' />

							<TextField
								fullWidth
								variant='outlined'
								id='auth-code'
								name='code'
								autoFocus
								sx={{
									marginTop: '3rem',
									background: '#282828 !important',
									color: 'white',
									'& .MuiInputBase-input': {
										background: '#282828 !important',
										color: 'white !important'
									},
									'& .MuiInputBase-root': {
										background: '#282828 !important'
									},
									'& .MuiFormLabel-filled': {
										background: '#282828 !important'
									}
								}}
								onChange={ev => {
									setCode(ev.target.value);
								}}
							/>
							<Typography
								style={{
									fontSize: '16px',

									marginBottom: '2rem',
									fontWeight: '400'
								}}
								color='red'
								textAlign={'center'}
							>
								{errorMessage}
							</Typography>

							<Box
								sx={{
									mt: 8,
									'& .MuiInputBase-root': {
										color: 'white !important',
										background: '#282828 !important'
									},

									'& .MuiInputLabel-outlined ': {
										color: 'white !important'
									},
									'& .MuiFormLabel-filled': {
										background: '#282828 !important'
									}
								}}
							>
								<Typography
									style={{
										fontSize: '14px',
										color: 'white',
										marginBottom: '1rem',
										fontWeight: '400'
									}}
									textAlign={'center'}
								>
									Scan not working? Use one of these codes.
								</Typography>
								<Typography
									style={{
										fontSize: '14px',
										color: 'white',
										marginBottom: '2rem',
										fontWeight: '400'
									}}
									textAlign={'center'}
								>
									Please store these codes securely as they will be not shown again
								</Typography>

								<Stack
									sx={{ background: '#282828 !important', padding: '2rem 2rem' }}
									direction={'row'}
									flexWrap={'wrap'}
									spacing={2}
									justifyContent={'center'}
								>
									{authData &&
										authData?.recovery_codes.map(code => {
											return (
												<Typography
													style={{
														fontSize: '14px',
														color: 'white',

														fontWeight: '700'
													}}
													textAlign={'center'}
												>
													{code}
												</Typography>
											);
										})}
								</Stack>
							</Box>
							<Button
								type='submit'
								fullWidth
								variant='contained'
								style={{
									marginTop: 16,
									margionBottom: 2,
									background: '#436ACD',
									color: 'white',
									fontSize: '16px',
									textTransform: 'capitalize'
								}}
								onClick={on2FAVerify}
							>
								Set-up
							</Button>
						</Box>
					</Grid>
				)}
			</Grid>
		</div>
	);
}
