import toastr from 'toastr';
export function changeEstimateUtil(
	item,
	direction,
	isTaskDetailsPopup = false,
	estimates = 0,
	tasks,
	setTasks,
	selectedTask,
	updateFullEstimate,
	updateTaskOnBackend,
	updateBudget,
	setSelectedTask,
	setTaskList = null,
	calculateEstimate = null,
	totalEstimate = 0,
	setTotalEstimate = null
) {
	// Your existing code for changeEstimate
	console.log(setTaskList);

	var childTasks = tasks.filter(function (todo) {
		return item._id === todo.parent_id;
	});

	let newEstimate;
	if (isTaskDetailsPopup) {
		newEstimate = totalEstimate - item.estimate + estimates;
	} else if (calculateEstimate) {
		newEstimate = calculateEstimate(direction, item.estimate);
	}

	if (setTotalEstimate) {
		setTotalEstimate(newEstimate);
	}

	if (childTasks.length === 0) {
		let updatedItem = {
			...item,
			todo_id: item._id,
			value: item.title,
			estimate: isTaskDetailsPopup ? estimates : calculateEstimate(direction, item.estimate),
			estimation_by: item.estimation_by
		};

		var taskListCopy = [...tasks];
		var index = taskListCopy.findIndex(function (value) {
			return value._id == updatedItem._id;
		});
		taskListCopy[index] = updatedItem;

		console.log(updatedItem, 'items');

		updateFullEstimate(updatedItem, true);
		// toastr.success(res.data.message);
		// updateTaskOnBackend(updatedItem);
		setTasks(taskListCopy);
		if (setTaskList) {
			setTaskList(taskListCopy);
		}
		updateBudget(updatedItem, taskListCopy);
		if (selectedTask) {
			setSelectedTask({
				...selectedTask,
				todo_id: item._id,
				value: item.title,
				estimate: isTaskDetailsPopup ? estimates : calculateEstimate(direction, item.estimate),
				estimation_by: item.estimation_by
			});
		}
	} else {
		toastr.clear();
		setTimeout(
			() =>
				toastr.warning(
					`Add budget to child entries, the budget of the current item will be auto-calculated. `
				),
			300
		);
	}
}
