import React, { useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
import Trix from 'trix';
import 'trix/dist/trix.css';
import * as axiosBase from 'axios';
import parse from 'html-react-parser';
import styles from './wysiwyg.module.css';
import { useSelector } from 'react-redux';
import Tribute from 'tributejs';
import 'tributejs/dist/tribute.css';
import './wysiwyg.css';
import CustomAvatar from './CustomAvatar/CustomAvatar';
import { renderToStaticMarkup } from 'react-dom/server';
import { LoadingButton } from '@mui/lab';

const accessToken = localStorage.getItem('access_token');
const axios = axiosBase.create({
	headers: {
		Accept: 'application/json',
		Authorization: `Bearer ${accessToken}`
	}
});

const Wysiwyg = ({
	setChatGPTResponse,
	chatGPTResponse,
	task,
	worksheetUsers = [],
	defaultValue = '',
	...props
}) => {
	const trixInput = useRef(null);
	const tributeRef = useRef(null);

	const [pendingUploads, setPendingUploads] = useState(0);
	const [escalationMessages, setEscalationMessages] = useState([]);
	const [users, setUsers] = useState(worksheetUsers || []);
	const presetMessages = useSelector(state => state.reducer.data);
	const [fetchingUsers, setFetchingUsers] = useState(!worksheetUsers?.length);

	const trixId = props.type ? `trix-${props.type}` : 'trix';

	const handleInsertMention = event => {
		let user = event.detail?.item?.original;
		let content = (
			<span className='tribute-mention d-flex'>
				<CustomAvatar
					avatar={user.avatar}
					alt='profile'
					name={user.name}
					style={{
						width: '20px',
						height: '20px',
						fontSize: '10px',
						fontWeight: 'bold',
						borderRadius: '100%'
					}}
				/>
				{user.name}
			</span>
		);
		let attachment = new Trix.Attachment({
			uid: user._id,
			content: renderToStaticMarkup(content),
			contentType: 'mention'
		});
		trixInput.current?.editor?.insertAttachment(attachment);
		trixInput.current?.editor?.insertString(' ');
	};

	const initTribute = targetInput => {
		const tribute = new Tribute({
			lookup: 'name',
			values: users.filter(user => user),
			noMatchTemplate: () => {
				let nodeToReturn = 'No matches found!';

				if (fetchingUsers) {
					nodeToReturn = 'Loading suggestions...';
				}

				return nodeToReturn;
			},
			selectTemplate: function (item) {
				return '@' + item.original.value;
			}
		});

		tribute?.attach(targetInput);

		tribute.range.pasteHtml = (html, startPos, endPos) => {
			let position = trixInput.current?.editor?.getPosition();
			trixInput.current?.editor?.setSelectedRange([position - (endPos - startPos), position]);
			trixInput.current?.editor?.deleteInDirection('backward');
		};

		// Must be removed later in when detaching
		targetInput?.addEventListener('tribute-replaced', handleInsertMention);

		tributeRef.current = tribute;
		return tribute;
	};

	const getUsers = useCallback(() => {
		if (task) {
			setFetchingUsers(true);
			const url = `${process.env.REACT_APP_LARAVEL_API_URL}/worksheet`;
			axios
				.get(url)
				.then(data => {
					const worksheets = data?.data?.worksheets;
					const currentWorkSheet = worksheets?.find(w => w?._id === task.worksheet_id);
					const validUsers = currentWorkSheet?.access?.map(a => a?.user);
					setUsers(validUsers);
					setFetchingUsers(false);
				})
				.catch(e => {
					setFetchingUsers(false);
				});
		} else {
			axios
				.get(`${process.env.REACT_APP_LARAVEL_API_URL}/users`)
				.then(res => {
					setUsers(res.data.users);
					setFetchingUsers(false);
				})
				.catch(e => {
					setFetchingUsers(false);
				});
		}
	}, [task]);
	useEffect(() => {
		if (chatGPTResponse) {
			trixInput.current.editor.insertHTML(chatGPTResponse);
			setChatGPTResponse('');
		}
	}, [chatGPTResponse]);

	useEffect(() => {
		if (!worksheetUsers.length) {
			getUsers();
		}
	}, [getUsers, worksheetUsers.length]);

	useEffect(() => {
		const targetInput = trixInput.current;
		let tributeInstance = initTribute(targetInput);

		return () => {
			// Detach tribute that was previouly attached to the input
			if (targetInput) {
				tributeInstance?.detach(targetInput);
				// Remove the attached event listener also
				targetInput.removeEventListener('tribute-replaced', handleInsertMention);
			}
		};
	}, [fetchingUsers, users]);

	useEffect(() => {
		let trixInputRef = trixInput.current;
		props.setLoading(pendingUploads > 0);

		const keydownEvent = e => {
			if (e.key == 'Enter' && (e.ctrlKey || e.metaKey)) {
				e.preventDefault();

				if (pendingUploads > 0) {
					return;
				}
				let editor = e.target.editor;
				let lastPosition = editor.getDocument().toString().length - 1;
				editor.setSelectedRange(lastPosition); // Move the cursor to last position to remove any selection
				if (props.isComment) {
					console.log('submitting comment');
					props.onSubmit(e.target.innerHTML);
					trixInputRef.innerHTML = '';
				} else {
					console.log('submitting-desc');
					props.onSubmit(e.target.innerHTML);
				}
			}
		};

		trixInputRef.addEventListener('keydown', keydownEvent);

		return () => {
			trixInputRef.removeEventListener('keydown', keydownEvent);
		};
	}, [pendingUploads]);

	useEffect(() => {
		trixInput.current.addEventListener('trix-change', event => {
			props.onChange(event.target.innerHTML); //calling custom event
		});

		trixInput.current.addEventListener('trix-attachment-add', function (event) {
			if (event.attachment.file) {
				uploadFileAttachment(event.attachment);
			}
		});

		trixInput.current.addEventListener('trix-initialize', function (event) {
			trixInput.current.editor.insertString(defaultValue);
		});
	}, [defaultValue]);

	const uploadFileAttachment = attachment => {
		setPendingUploads(count => count + 1);
		uploadFile(attachment.file, setProgress, setAttributes, uploadError);

		function setProgress(progress) {
			attachment.setUploadProgress(progress);
		}

		function setAttributes(attributes) {
			attachment.setAttributes(attributes);
			setPendingUploads(count => count - 1);
		}

		function uploadError(error) {
			alert(error);
			attachment.remove();
			setPendingUploads(count => count - 1);
		}
	};

	const uploadFile = (file, progressCallback, successCallback, errorCallback) => {
		axios
			.get(`${process.env.REACT_APP_LARAVEL_API_URL}/file/upload/s3`, {
				params: { filename: file.name }
			})
			.then(res => {
				var data = res.data.data;
				var xhr = new XMLHttpRequest();

				xhr.open('PUT', data.presignedUrl, true);
				xhr.setRequestHeader('Content-type', file.type);

				xhr.upload.addEventListener('progress', function (event) {
					var progress = (event.loaded / event.total) * 100;
					progressCallback(progress);
				});

				xhr.addEventListener('load', function (event) {
					if (xhr.status == 200) {
						var attributes = {
							url: data.url,
							href: data.url + '?content-disposition=attachment'
						};
						successCallback(attributes);
					} else {
						errorCallback(xhr.statusText);
					}
				});

				xhr.send(file);
			})
			.catch(err => {
				console.log(err);
				errorCallback(err.message);
			});
		/* 
		var formData = new FormData()
		formData.append("Content-Type", file.type)
		formData.append("file", file)

		var xhr = new XMLHttpRequest()
		xhr.responseType = 'json'

		xhr.open("POST", `${process.env.REACT_APP_LARAVEL_API_URL}/file/upload`, true)
		xhr.setRequestHeader('Accept', 'application/json')

		xhr.upload.addEventListener("progress", function(event) {
			var progress = event.loaded / event.total * 100
			progressCallback(progress)
		})

		xhr.addEventListener("load", function(event) {
			if (xhr.status == 200) {
				const response = xhr.response
				var attributes = {
					url: response.url,
					href: response.url + "?content-disposition=attachment"
				}
				successCallback(attributes)
			} else if (xhr.status == 413) {
				errorCallback(xhr.statusText)
			} else {
				errorCallback(xhr.response.message)
			}
		})

		xhr.send(formData)
 */
	};

	useEffect(() => {
		if (presetMessages && props.customMessageType) {
			setEscalationMessages(presetMessages.filter(r => r.category === props.customMessageType));
		}
	}, [presetMessages, props.customMessageType]);
	const submitBtnLabel =
		props.commentButtons?.length &&
		props.commentButtons.find(comment => comment.key === props.customMessageType)?.label;

	return (
		<>
			<div className='mb-4 trix-container' style={{ backgroundColor: '#24272D', color: '#fff' }}>
				<input type='hidden' id={trixId} value={!chatGPTResponse ? props.value : ''} />
				<trix-editor
					input={trixId}
					ref={trixInput}
					onFocus={() => {
						if (typeof props.onFocus === 'function') {
							props.onFocus();
						}
					}}
					onBlur={() => {
						if (typeof props.onBlur === 'function') {
							props.onBlur();
						}
					}}
				/>
			</div>

			{escalationMessages.length === 0 && props.isComment === true && (
				<>
					<LoadingButton
						onClick={e => {
							props.onSubmit();
						}}
						loading={props.btnLoading}
						variant='contained'
						disabled={pendingUploads > 0}
						className='button__wysiwyg submit__wysiwyg'
					>
						Add {submitBtnLabel}
					</LoadingButton>
					<LoadingButton
						className='mx-3 button__wysiwyg cancel__wysiwyg'
						color='error'
						onClick={e => {
							props.setCommentType('comment');
							props.setShowCommentBox(false);
							if (typeof props.setCommentContent === 'function') {
								props.setCommentContent('');
							}
						}}
						loading={props.btnLoading}
						variant='contained'
						disabled={pendingUploads > 0}
					>
						Cancel
					</LoadingButton>
				</>
			)}

			{props.showCustomMessage && escalationMessages.length > 0 && (
				<>
					{props.specialCommentShow && (
						<LoadingButton
							onClick={() => {
								props.onSubmit();
							}}
							loading={props.btnLoading}
							variant='contained'
						>
							Save
						</LoadingButton>
					)}

					{!props.specialCommentShow && (
						<>
							<LoadingButton
								onClick={e => {
									props.onSubmit();
								}}
								loading={props.btnLoading}
								variant='contained'
								className='button__wysiwyg submit__wysiwyg'
							>
								Add {submitBtnLabel}
							</LoadingButton>
							<LoadingButton
								className='mx-3 button__wysiwyg cancel__wysiwyg'
								color='error'
								onClick={e => {
									props.setCommentType('comment');
									props.setShowCommentBox(false);
								}}
								loading={props.btnLoading}
								variant='contained'
							>
								Cancel
							</LoadingButton>
						</>
					)}

					<div className='pre-msg'>
						<div className='d-flex ' style={{ gap: '10px', flexDirection: 'column' }}>
							{escalationMessages.map((value, index) => {
								return (
									<div
										className={`d-flex justify-content-between ${styles.custom_message}`}
										key={index}
									>
										<div
											className={`py-3`}
											onClick={() => {
												trixInput.current.editor.insertHTML(value.message);
											}}
											style={{
												cursor: 'pointer',
												flex: 1
												// width: '90%'
											}}
										>
											<div className='text-normal mb-0 d-flex align-items-center'>
												<div className='pre-message-text'>{parse(value.message)}</div>
											</div>
										</div>
										<div className={`${styles['pre_btn__container']}`}>
											<button
												className={`${styles['pre__btn']} ${styles['paste_pre__btn']}`}
												onClick={() => {
													trixInput.current.editor.insertHTML(value.message);
												}}
											>
												Paste
											</button>
											<button
												className={`${styles['pre__btn']} ${styles['send_pre__btn']}`}
												onClick={e => {
													e.stopPropagation();
													e.preventDefault();
													props.onSubmit(value.message);
												}}
											>
												Send
											</button>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default Wysiwyg;
