import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import * as axiosBase from 'axios';
import parse from 'html-react-parser';
import styles from './ChoppingInterface.module.css';
import Box from '@mui/material/Box';
import Wysiwyg from 'components/wysiwyg';
import moment from 'moment';
import { ObjectID } from 'bson';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import LoadingButton from '@mui/lab/LoadingButton';
import DisplayBreadcrumbs from 'components/DisplayBreadcrumbs/DisplayBreadcrumbs';
import {
	Backdrop,
	Button,
	Chip,
	CircularProgress,
	ListItemText,
	makeStyles,
	Menu,
	MenuItem,
	Modal,
	TextField
} from '@material-ui/core';
import useCommentButtons from 'hooks/useCommentButtons';
import CommentIcon from 'components/CommentIcon/CommentIcon';
import { useDispatch } from 'react-redux';
import { fetchPresetMessages } from 'reducers/presetMessages';
import { ThreeDotsVertical } from 'assets/icons';
import CustomAvatar from 'components/CustomAvatar/CustomAvatar';
import { AiOutlineCopy, AiOutlineLink } from 'react-icons/ai';
import { FaRobot, FaTimes } from 'react-icons/fa';
import { HiClipboardCopy } from 'react-icons/hi';
import Linkify from 'react-linkify';
import { useSelector } from 'react-redux';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import './TaskDetails.css';
import { mobileTimeFormatter } from 'utils/mobileTimeFormat';
import ChildTasks from './ChildTasks';
import {
	fetchTaskDetails,
	fetchTasksWithNewComments,
	fetchUsers,
	fetchWorkSheetList,
	fetchWorkSheetTasks
} from 'api';
import { cacheTaskInRecentlyVisitedList } from 'utils/cacheUtils';
import VideoPlayer from 'components/VideoPlayer';
import './GptResponse.css';
import GradientBorder from 'components/GradientBorder/GradientBorder';
import TaskMetaDataContainer from './components/TaskMetaDataContainer/TaskMetaDataContainer';
import AvatarWithAction from 'components/AvtarWithAction/AvatarWithAction';
import { BorderBottom } from '@material-ui/icons';
import Hotkeys from './components/Hotkeys/Hotkeys';
import marked from 'marked';

const videoExtensionsRegex = /\.(mp4|m3u8|webm|ogg|avi|flv|mov|mkv|avi|wvm|flv|avchd)(\?.*)?$/;

const accessToken = localStorage.getItem('access_token');
const axios = axiosBase.create({
	headers: {
		Accept: 'application/json',
		Authorization: `Bearer ${accessToken}`
	}
});
const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};

const useStyles = makeStyles({
	TextField: {
		fontSize: '15px !important',
		background: 'white !important',
		width: '280px !important',
		padding: '5px 5px 0px'
	},
	backDrop: {
		color: '#fff',
		backgroundColor: 'none',
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex: 20000,
		opacity: 0.5,
		height: '200px',
		width: '100%'
	}
});

const parseOptions = {
	replace: node => {
		if (node.type === 'tag' && node.name === 'img') {
			let parent = node.parent;
			while (parent && parent.type !== 'tag') {
				parent = parent.parent;
			}
			if (
				parent &&
				parent.type === 'tag' &&
				parent.name === 'a' &&
				parent.attribs &&
				parent.attribs.href
			) {
				node.attribs.src = parent.attribs.href;
				node.attribs.style = 'width: 100%; height: auto; object-fit: contain; max-width: 100%;';
			}
		}
		if (node.type === 'tag' && node.name === 'a' && videoExtensionsRegex.test(node.attribs?.href)) {
			return (
				<VideoPlayer
					src={node.attribs.href}
					videoProps={{
						height: 500
					}}
				/>
			);
		}
		if (
			node.type === 'tag' &&
			node.attribs &&
			node.attribs.class &&
			node.attribs.class.includes('attachment--content')
		) {
			node.attribs.style = 'display: inline-block; vertical-align: middle;';
		}
		return node;
	}
};

toastr.options = {
	positionClass: 'toast-top-center',
	hideDuration: 300,
	timeOut: 5000
};

const TaskDetails = ({
	activeWorksheet,
	selectedTask = null,
	item_id,
	updateTaskOnChoppingInterface,
	openNextTaskOnNotficationPage,
	shouldScrollToComment,
	parentTitle,
	comment_id = null,
	showDescBox,
	hyperlinkedComment,
	changeEstimate,
	updatePrefetchedTask,
	openedTask
}) => {
	const presetMessages = useSelector(state => state.reducer.data);

	const { task_id: task_id_from_url } = useParams();
	useEffect(() => {
		if (item_id) {
			setTaskId(item_id);
		}
	}, [item_id]);

	useEffect(() => {
		if (task_id_from_url) {
			setTaskId(task_id_from_url);
		}
	}, [task_id_from_url]);

	const [taskId, setTaskId] = useState();
	const [task, setTask] = useState(() => {
		if (selectedTask) {
			return selectedTask;
		} else {
			return openedTask;
		}
	});
	const [comments, setComments] = useState(null);
	const [commentContent, setCommentContent] = useState('');
	const [defaultValueForEditor, setDefaultValueForEditor] = useState('');
	const [showCommentBox, setShowCommentBox] = useState();
	const [descriptionContent, setDescriptionContent] = useState(() => {
		return openedTask && openedTask.description ? openedTask.description : '';
	});
	const [showDescriptionBox, setShowDescriptionBox] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const leaderMenu = user.role === 'team_lead' || user.role === 'owner';
	const [commentType, setCommentType] = useState('');
	const [openTaskCommentModal, setOpenTaskCommentModal] = useState(false);
	const [commentButtons] = useCommentButtons();
	const [editCommentIds, setEditCommentIds] = useState([]);
	const [loading, setLoading] = useState(false);
	const [activeUserName, setActiveUserName] = useState('');
	const [lineage, setLineage] = useState([]);
	const lastCommentRef = useRef(null);
	const notificationCommentRef = useRef(null);
	const [users, setUsers] = useState([]);
	const [worksheetUsers, setWorksheetUsers] = useState([]);
	const [openDeliveryModal, setOpenDeliveryModal] = useState(false);
	const [deliveryContent, setDeliveryContent] = useState(true);
	const [scheduledDate, setScheduledDate] = useState(new Date());
	const [taskCommentId, setTaskCommentId] = useState('');
	const [childData, setChildData] = useState({
		status: 'Loading...',
		childrens: [],
		worksheet: [],
		isOpen: false
	});
	const [estimates, setEstimates] = useState(() => {
		return openedTask && openedTask.estimate ? openedTask.estimate : '0';
	});
	const [isEditorFocused, setIsEditorFocused] = useState(false);
	const [tasksWithNewComments, setTasksWithNewComments] = useState({});
	const [isModalOpened, setIsModalOpened] = useState(false);
	const [newCommentId, setNewCommentId] = useState('');
	const [searchParams, setSearchParams] = useSearchParams();
	const [chatGPTResponse, setChatGPTResponse] = useState('');

	const classes = useStyles();
	const dispatch = useDispatch();

	const ownerId = users?.find(_user => _user.role === 'owner')?._id;
	const isTaskAuthoredByOwner = ownerId === task?.user_id;

	const replaceAssignee = title => {
		let newTitle = title;
		if (title.includes('@')) {
			users.forEach(user => {
				if (title.includes(user.name)) {
					newTitle = title.replaceAll(user.name, '').replaceAll('@', '');
				}
			});
		}
		return newTitle;
	};

	React.useEffect(() => {
		if (taskId) {
			getData();
			const cachedComment = localStorage.getItem(`task-${taskId}-comment`);
			const cachedCommentType = localStorage.getItem(`task-${taskId}-comment-type`);
			if (cachedComment) {
				setCommentContent(cachedComment);
			}
			if (cachedCommentType) {
				setCommentType(cachedCommentType);
			}
		}
	}, [taskId]);

	useEffect(() => {
		if (selectedTask) {
			setTask(selectedTask);
		}
	}, [selectedTask]);

	useEffect(() => {
		const handleKeyPress = event => {
			if (event.ctrlKey && event.shiftKey && event.key === 'I') {
				addCommentOfClientConvWithShortcut();
			} else if (event.key === 'T' && event.shiftKey) {
				handleCopyTitle();
			} else if (event.key === 'D' && event.shiftKey) {
				handleCopyWithTick();
			} else if (event.key === 'L' && event.shiftKey) {
				handleCopyWithLineage();
			} else if (event.key === 'S' && event.shiftKey) {
				handleCopyTextSubmission();
			} else if (event.key === 'W' && event.shiftKey) {
				handleCopyClientConversation();
			} else if (event.key === 'Q' && event.shiftKey) {
				handleCopyAsQuote();
			} else if (event.key === 'P' && event.shiftKey) {
				handleCopyWithP1();
			}
		};

		if (task && !isEditorFocused) {
			document.addEventListener('keydown', handleKeyPress);
		}

		// Cleanup the event listener when the component unmounts
		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, [task, lineage, comments, isEditorFocused]);

	const handleToggleModals = toggle => {
		if (toggle) {
			setIsModalOpened(true);
		} else {
			setTimeout(() => {
				setIsModalOpened(false);
			}, 350);
		}
	};

	const playBeepSound = async (config = {}) => {
		const { duration = 100, frequency = 500, volume = 10 } = config;

		return new Promise((resolve, reject) => {
			const myAudioContext = new AudioContext();

			try {
				let oscillatorNode = myAudioContext.createOscillator();
				let gainNode = myAudioContext.createGain();
				oscillatorNode.connect(gainNode);

				// Set the oscillator frequency in hertz
				oscillatorNode.frequency.value = frequency;

				// Set the type of oscillator
				oscillatorNode.type = 'square';
				gainNode.connect(myAudioContext.destination);

				// Set the gain to the volume
				gainNode.gain.value = volume * 0.01;

				// Start audio with the desired duration
				oscillatorNode.start(myAudioContext.currentTime);
				oscillatorNode.stop(myAudioContext.currentTime + duration * 0.001);

				// Resolve the promise when the sound is finished
				oscillatorNode.onended = () => {
					resolve();
				};
			} catch (error) {
				reject(error);
			}
		});
	};

	// useEffect(() => {
	// 	if (updateTaskOnChoppingInterface && task) {
	// 		let temp_task = task;
	// 		temp_task.has_notification = false;
	// 		updateTaskOnChoppingInterface(temp_task);
	// 	}
	// }, [task]);

	const addCommentOfClientConvWithShortcut = async () => {
		localStorage.setItem(`task-${taskId}-comment-type`, 'client_conversation');
		const textToBePasted = await navigator.clipboard.readText();
		const html = '<div><!--block-->' + textToBePasted + '</div>';
		setShowCommentBox(true);
		setCommentType('client_conversation');
		setDefaultValueForEditor(textToBePasted);
		localStorage.setItem(`task-${taskId}-comment`, html);
		playBeepSound();
	};

	const handleCopyTitle = () => {
		let cleanTitle = replaceAssignee(task?.title)?.replace(/@\w+(\s\w+){0,2}/, '');
		navigator.clipboard
			.writeText(cleanTitle)
			.then(() => {
				setTimeout(() => toastr.success(`Title copied to clipboard.`), 300);
			})
			.catch(error => console.error('Error copying text: ', error));
		playBeepSound();
	};

	const handleCopyWithTick = () => {
		let cleanTitle = replaceAssignee(task?.title)?.replace(/@\w+(\s\w+){0,2}/, '');
		navigator.clipboard
			.writeText('✅ ' + cleanTitle)
			.then(() => {
				setTimeout(() => toastr.success(`Title copied to clipboard with ✅.`), 300);
			})
			.catch(error => console.error('Error copying text: ', error));
		playBeepSound();
	};

	const handleCopyWithLineage = () => {
		let shortenedLineage = [...lineage].join(' > ');
		if (shortenedLineage.includes('@')) {
			users.forEach(user => {
				if (shortenedLineage.includes(user.name)) {
					shortenedLineage = shortenedLineage.replaceAll(user.name, '').replaceAll('@', '');
				}
			});
		}

		navigator.clipboard
			.writeText(shortenedLineage)
			.then(() => {
				setTimeout(() => toastr.success(`Title copied to clipboard with lineage.`), 300);
			})
			.catch(error => console.error('Error copying text: ', error));

		playBeepSound();
	};

	const handleCopyTextSubmission = () => {
		let cleanTitle =
			'✅ ' + replaceAssignee(task?.title)?.replace(/@\w+(\s\w+){0,2}/, '') + '<br><br>';
		let comment = comments.filter(item => item.type === 'submission')[
			comments.filter(item => item.type === 'submission').length - 1
		];

		navigator.clipboard
			.writeText(
				cleanTitle.trim().replaceAll('<br>', '\n') +
					comment?.content
						.replaceAll('<li>', '\n')
						.replaceAll('</h1>', '\n')
						.replaceAll('<br>', '\n')
						.replace(/<\/?[^>]+(>|$)/g, '')
						.replace(/&amp;|&&nbsp/g, '&')
						.replace(/&nbsp;/g, '\n')
						.replace(/&lt;/g, '<')
						.replace(/&gt;/g, '>')
						.trim()
			)
			.then(() => {
				setTimeout(() => toastr.success(`Comment text submission copied to clipboard.`), 300);
			})
			.catch(error => console.error('Error copying text: ', error));

		if (['owner', 'team_lead'].includes(user.role)) {
			const deliveredCommentData = {
				category: 'approve',
				comment: '<div><!--block-->Delivered.</div>'
			};
			handlePresetCommentSubmit(deliveredCommentData.category, deliveredCommentData.comment);
		}

		playBeepSound();
	};

	const handleCopyClientConversation = () => {
		let cleanTitle = '> ' + task?.title.trim().replace(/@\w+(\s\w+){0,2}/, '') + '<br><br>';
		let comment = comments.filter(item => item.type === 'client_conversation')[
			comments.filter(item => item.type === 'client_conversation').length - 1
		];

		navigator.clipboard
			.writeText(
				cleanTitle.trim().replaceAll('<br>', '\n') +
					comment?.content
						.replaceAll('<li>', '\n')
						.replaceAll('</h1>', '\n')
						.replaceAll('<br>', '\n')
						.replace(/<\/?[^>]+(>|$)/g, '')
						.replace(/&amp;|&&nbsp/g, '&')
						.replace(/&nbsp;/g, '\n')
						.replace(/&lt;/g, '<')
						.replace(/&gt;/g, '>')
						.trim()
			)
			.then(() => {
				setTimeout(() => toastr.success(`Client conversation copied to clipboard.`), 300);
			})
			.catch(error => console.error('Error copying text: ', error));

		playBeepSound();
	};

	const handleCopyAsQuote = () => {
		let cleanTitle = replaceAssignee(task?.title)?.replace(/@\w+(\s\w+){0,2}/, '');
		navigator.clipboard
			.writeText('>' + ' ' + cleanTitle)
			.then(() => {
				setTimeout(() => toastr.success(`Title copied as quote.`), 300);
			})
			.catch(error => console.error('Error copying text: ', error));
		playBeepSound();
	};

	const handleCopyWithP1 = () => {
		let text =
			(parentTitle?.replace(/@\w+(\s\w+){0,2}/, '') || '') +
			' > ' +
			task?.title.replace(/@\w+(\s\w+){0,2}/, '');
		navigator.clipboard
			.writeText('✅ ' + text)
			.then(() => {
				setTimeout(() => toastr.success(`Title copied to clipboard with ✅.`), 300);
			})
			.catch(error => console.error('Error copying text: ', error));

		playBeepSound();
	};

	const getTask = async (config = {}) => {
		const { showLoader = true } = config;
		if (showLoader) {
			setLoading(true);
		}
		try {
			const response = await fetchTaskDetails(taskId, { refresh: true });
			return response.data.data;
		} catch (error) {
			throw error;
		}
	};

	const getTasksWithNewComments = async (options = {}) => {
		const worksheetId = activeWorksheet?._id ?? task?.worksheet_id;
		try {
			const response = await fetchTasksWithNewComments({
				worksheet_id: worksheetId,
				...options
			});

			const responseData = response.data;
			const notificationCheckByTaskIDs = responseData.data || {};

			setTasksWithNewComments(notificationCheckByTaskIDs);
		} catch (error) {
			console.log(error);
		}
	};

	const nextMonday = () => {
		const date = new Date();
		const dateCopy = new Date(date.getTime());
		const nextMonday = new Date(
			dateCopy.setDate(dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7))
		);
		setScheduledDate(nextMonday);
	};

	const tomorrowDate = () => {
		const today = new Date();
		const tomorrow = new Date(today);
		tomorrow.setDate(today.getDate() + 1);
		setScheduledDate(tomorrow);
	};

	const getComments = () => {
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/comment?todo_id=${taskId}`;
		return axios.get(url).then(res => {
			return res.data.data;
		});
	};

	const getData = () => {
		if (showDescBox) {
			setShowDescriptionBox(true);
		}
		if (!selectedTask) {
			setLoading(true);
			Promise.all([getTask(), getComments()])
				.then(([taskData, commentsData]) => {
					setComments(commentsData || []);

					//* set the description to new content only if the there was no description
					setTask(prevTask => {
						return { ...taskData, description: prevTask.description || taskData.description };
					});
					setEstimates(taskData?.estimate);
					setDescriptionContent(prevDesc => prevDesc || taskData.description);
				})
				.catch(error => {
					console.error(error);
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			setComments(selectedTask.comments || []);
			setEstimates(selectedTask?.estimate);
			setDescriptionContent(selectedTask.description);
			getTask({
				showLoader: false
			});
		}
	};

	const generateObjectId = () => {
		const id = new ObjectID();
		const objectid = id.toString();

		return objectid;
	};

	const addComment = (comment_type, temp_comment = false) => {
		const objectid = generateObjectId();
		setShowCommentBox(false);
		setNewCommentId(objectid);
		const newComment = {
			todo_id: taskId,
			content: temp_comment ? temp_comment : commentContent,
			user: user,
			user_id: user._id,
			temp_id: objectid,
			type: comment_type
		};
		setComments(prev => [...prev, newComment]);

		let temp_task = task;

		if (temp_task.last_comment_type === 'submission') {
			if (comment_type === 'reopen' || comment_type === 'approve') {
				temp_task.last_comment_type = comment_type;
				if (comment_type === 'approve') {
					temp_task.completed = true;
				}
			}
		} else {
			temp_task.last_comment_type = comment_type;
		}
		temp_task.comments_count++;
		if (updateTaskOnChoppingInterface) {
			updateTaskOnChoppingInterface(temp_task);
		}

		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/comment`;

		axios
			.post(url, {
				todo_id: taskId,
				content: temp_comment ? temp_comment : commentContent,
				type: comment_type
			})
			.then(res => {
				if (res.data.success) {
					setCommentContent('');
					setComments(prev => {
						return prev.map(i => {
							if (i.temp_id === objectid) {
								return res.data.data;
							}
							return i;
						});
					});

					if (selectedTask) {
						updatePrefetchedTask(taskId, {
							comments: [...selectedTask.comments, res.data.data]
						});
					}

					toastr.clear();
					setTimeout(() => {
						toastr.success(`Comment added successfully. `);
						setCommentContent('');
					}, 300);

					if (openNextTaskOnNotficationPage) {
						openNextTaskOnNotficationPage();
					}
				} else {
					setComments(prev => {
						return prev.filter(r => r.temp_id !== objectid);
					});
					setShowCommentBox(true);
					toastr.clear();
					setTimeout(
						() => toastr.error(`Something went wrong while commenting. Please try again `),
						300
					);
				}
			});
		setCommentContent('');
	};

	const updateEstimate = e => {
		setEstimates(e.target.value);
		changeEstimate(task, '', true, Number(e.target.value));

		toastr.clear();
	};

	const printDate = rawdate => {
		if (rawdate) {
			var date = moment(rawdate);
			var now = moment();
			if (now.diff(date, 'hours') < 24) {
				return date.fromNow();
			} else {
				return date.format('MMMM Do');
			}
		}
		return '';
	};

	const handleDescriptionSubmit = desc => {
		console.log(desc, 'desc-content');
		console.log(task);
		let temp_task = task;
		temp_task.value = temp_task.title;
		temp_task.description = desc || descriptionContent;
		console.log(temp_task);
		updateTask(temp_task);
		setTask(r => {
			r.description = desc || descriptionContent;
			return r;
		});

		setShowDescriptionBox(false);
	};

	const updateTask = temp_task => {
		// if (temp_task.description !== task.description) {
		if (updateTaskOnChoppingInterface) {
			updateTaskOnChoppingInterface(task);
		}
		toastr.clear();
		const body = {
			...task,
			hide_children: task.hide_children !== null ? task.hide_children : false
		};
		axios
			.put(`${process.env.REACT_APP_LARAVEL_API_URL}/task/${task._id}`, body, {
				// headers: {
				// 	Authorization: `Bearer ${token}`,
				// 	'Access-Control-Allow-Origin': '*'
				// }
			})
			.then(res => {
				if (res.data.success === true) {
					setTimeout(() => toastr.success(`Description updated successfully. `), 300);
				} else {
					setTimeout(
						() =>
							toastr.error(
								`Something went wrong while updating description. Please try again later`
							),
						300
					);
				}
			})
			.catch(error => {
				setTimeout(
					() =>
						toastr.error(`Something went wrong while updating description. Please try again later`),
					300
				);
			});
	};

	const handleOpenTaskCommentModal = (task, comment_type) => {
		setCommentType(comment_type);
		setOpenTaskCommentModal(true);
		handleToggleModals(true);
	};
	const handleCloseTaskCommentModal = () => {
		setOpenTaskCommentModal(false);
		handleToggleModals(false);
	};

	const handleCommentSubmit = (comment_temp = false) => {
		let type = localStorage.getItem(`task-${taskId}-comment-type`);
		const comment_type = commentType;
		addComment(type, comment_temp);
		// setTimeout(()=>addComment(comment_type, comment_temp),200)
		setTimeout(() => setCommentContent(''), 300);
		handleCloseTaskCommentModal();
		localStorage.removeItem(`task-${taskId}-comment`);
		localStorage.removeItem(`task-${taskId}-comment-type`);
	};

	const handlePresetCommentSubmit = (type, message) => {
		addComment(type, message);
	};

	const handleDeliveryModalOpen = (content, id) => {
		setShowCommentBox(false);
		setOpenDeliveryModal(true);
		setDeliveryContent(content);
		setTaskCommentId(id);
	};

	const handleAddToDelivery = (type, id, message) => {
		let data = {
			message: deliveryContent,
			task_comment_id: taskCommentId,
			scheduled_date:
				scheduledDate.getFullYear() +
				'-' +
				('0' + (scheduledDate.getMonth() + 1)).slice(-2) +
				'-' +
				('0' + scheduledDate.getDate()).slice(-2)
		};
		if (type === 'exact') {
			data.task_comment_id = id;
			data.message = message;
		}
		if (type === 'empty') {
			data.task_comment_id = id;
			data.message = '';
		}

		// {
		// 	message: deliveryContent,
		// 	task_comment_id: taskCommentId,
		// 	scheduled_date: scheduledDate.getFullYear() + "-" + ("0" + (scheduledDate.getMonth() + 1)).slice(-2) + "-" + ("0" + scheduledDate.getDate()).slice(-2),
		// }
		axios
			.post(`${process.env.REACT_APP_LARAVEL_API_URL}/client-message/delivery`, data)
			.then(res => {
				if (res.data.success) {
					setOpenDeliveryModal(false);
					toastr.clear();
					console.log(res.data);
					setTimeout(() => toastr.success(`Delivery Added successfully`), 300);
				}
			})
			.catch(err => console.log(err));
	};

	const handleUndoDelivery = id => {
		axios
			.delete(`${process.env.REACT_APP_LARAVEL_API_URL}/client-message/${id}`)
			.then(res => {
				if (res.data.success === true) {
					toastr.success('Undelivered');
					let prev = [...comments];
					prev = prev.map(item => {
						if (item._id === id) {
							return { ...item, is_delivered: false };
						} else {
							return item;
						}
					});
					setComments(prev);
				}
			})
			.catch(e => {
				console.log(e);
			});
	};

	const editComment = temp_comment => {
		let all_comments = [...comments];
		setBtnLoading(true);
		setComments(prev => {
			let index = prev.findIndex(r => r._id === temp_comment._id);
			prev[index].content = temp_comment.content;
			return prev;
		});

		if (selectedTask) {
			const newComments = selectedTask.comments.map(comment => {
				if (comment._id === temp_comment._id) {
					return {
						...comment,
						content: temp_comment.content
					};
				}
				return { ...comment };
			});
			updatePrefetchedTask(taskId, {
				comments: newComments
			});
		}

		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/comment/${temp_comment._id}`;
		axios
			.put(url, {
				content: temp_comment.content
			})
			.then(res => {
				if (res.data.success) {
					setEditFalse(temp_comment);
					toastr.clear();
					setTimeout(() => toastr.success(`Comment updated successfully. `), 300);
				} else {
					setComments(all_comments);
					toastr.clear();
					setTimeout(
						() => toastr.error(`Something went wrong while commenting. Please try again `),
						300
					);
				}
			})
			.finally(() => {
				setBtnLoading(false);
			});
	};

	const deleteComment = temp_comment => {
		if (!window.confirm('Are you sure you want to delete this comment?')) return;
		let all_comments = [...comments];
		setBtnLoading(true);
		setComments(prev => {
			return prev.filter(r => r._id !== temp_comment._id);
		});

		if (selectedTask) {
			updatePrefetchedTask(taskId, {
				comments: selectedTask.comments.filter(comment => comment._id !== temp_comment._id)
			});
		}

		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/comment/${temp_comment._id}`;
		axios.delete(url).then(res => {
			if (res.data.success) {
				toastr.clear();
				setTimeout(() => toastr.success(`Comment deleted successfully. `), 300);
			} else {
				setComments(all_comments);
				toastr.clear();
				setTimeout(
					() => toastr.error(`Something went wrong while commenting. Please try again `),
					300
				);
			}
		});
		setBtnLoading(false);
	};

	useEffect(() => {
		dispatch(fetchPresetMessages());
	}, [dispatch]);

	const setEditTrue = comment => {
		setEditCommentIds(prev => [...prev, comment._id]);
	};

	const setEditFalse = comment => {
		setEditCommentIds(prev => {
			return prev.filter(r => r !== comment._id);
		});
	};

	const autoSuggestCommentType = {
		submission: 'approve',
		escalation: 'de-escalation',
		approve: 'reopen'
	};

	useEffect(() => {
		const getUsers = async () => {
			try {
				const response = await fetchUsers({ refresh: true });
				setUsers(response?.data?.users);
				const userDetails = response?.data?.users.find(user => user?._id === task?.user_id);
				setActiveUserName(userDetails?.name);
			} catch (error) {
				console.error(error);
			}
		};
		getUsers();
		getTasksWithNewComments();
	}, [task]);

	useEffect(() => {
		const getWorkSheetUsers = async () => {
			try {
				const response = await fetchWorkSheetList({ refresh: true });
				const worksheets = response?.data?.worksheets;
				const currentWorkSheet = worksheets?.find(w => w?._id === task.worksheet_id);
				const validUsers = currentWorkSheet?.access?.map(a => a?.user) || [];
				setWorksheetUsers(validUsers);
			} catch (error) {
				console.error('getWorkSheetUsers ERROR ==> ', error);
			}
		};
		if (activeWorksheet) {
			const validUsers = activeWorksheet.access?.map(a => a?.user) ?? [];
			setWorksheetUsers(validUsers);
		} else if (task) {
			getWorkSheetUsers();
		}
	}, [task]);

	useEffect(() => {
		if (task && comments && ['owner', 'team_lead'].includes(user.role)) {
			if (task.last_comment_type === 'submission') {
				setCommentType(autoSuggestCommentType[task.last_comment_type]);
				setShowCommentBox(true);
				localStorage.setItem(
					`task-${taskId}-comment-type`,
					autoSuggestCommentType[task.last_comment_type]
				);
			} else if (task.last_comment_type === 'approve') {
				setCommentType(autoSuggestCommentType[task.last_comment_type]);
				setShowCommentBox(true);
				localStorage.setItem(
					`task-${taskId}-comment-type`,
					autoSuggestCommentType[task.last_comment_type]
				);
			} else if (comments.length === 0 && moment().diff(moment(task.created_at), 'minutes') < 5) {
				setCommentType('involve');
				setShowCommentBox(true);
				localStorage.setItem(`task-${taskId}-comment-type`, 'involve');
			} else if (['submission', 'escalation'].includes(task.last_comment_type)) {
				setCommentType(autoSuggestCommentType[task.last_comment_type]);
				setShowCommentBox(true);
				localStorage.setItem(
					`task-${taskId}-comment-type`,
					autoSuggestCommentType[task.last_comment_type]
				);
			} else {
				setCommentType('followup');
				setShowCommentBox(true);
				localStorage.setItem(`task-${taskId}-comment-type`, 'followup');
			}
		}
		setTimeout(() => console.log(commentType), 300);
	}, [comments, task]);

	useEffect(() => {
		if (!task) return; // check if task is defined
		let cleanTitle = task?.title.replace(/@\w+(\s\w+){0,2}/, '');
		const titles = task.parents?.map(parent => parent?.title).concat(cleanTitle) || []; // check if parents array and title property are defined
		setLineage([task.worksheet?.title, ...titles]);
	}, [task]);

	useEffect(() => {
		if (lastCommentRef.current) {
			setTimeout(() => {
				lastCommentRef.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'start'
				});
			}, 500);
		}
	}, [comments]);

	useEffect(() => {
		if (notificationCommentRef.current) {
			setTimeout(() => {
				notificationCommentRef.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'start'
				});
			}, 500);
		}
	}, [comments]);

	const getChildrens = async () => {
		let children = [];
		if (task) {
			try {
				const response = await fetchWorkSheetTasks(task.worksheet_id, { refresh: true });
				response?.data?.tasks.forEach(crr => {
					if (crr.parent_id === task._id) {
						children = children.concat(crr);
					}
				});

				setChildData({
					...childData,
					status: children.length,
					childrens: children,
					worksheet: response.data.tasks
				});
			} catch (error) {
				console.error(error);
			}
		}
	};
	useEffect(() => {
		if (task) {
			getChildrens();
		}
	}, [task]);

	const contains = (arr, el) => {
		let hasElement = false;
		arr?.forEach(item => {
			if (item?._id === el?._id) hasElement = true;
		});
		return hasElement;
	};

	useEffect(() => {
		let recentlyVisitedTasks = JSON.parse(localStorage.getItem('recently_visited_tasks')) || [];
		if (task && !contains(recentlyVisitedTasks, task)) {
			cacheTaskInRecentlyVisitedList(task);
		}
	}, [task]);
	const [isEstimateVsisble, setIsEstimateVisible] = useState(false);

	const parseText = text => {
		let temp_text = text.replace(
			/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1[^>]*>/gi,
			`<a target="_blank" rel="noopener noreferrer"  href="$2" style="color: white;text-decoration:underline">`
		);

		return (
			<Linkify
				componentDecorator={(decoratedHref, decoratedText, key) => (
					<a
						href={decoratedHref}
						key={key}
						target='_blank'
						style={{ color: 'white', textDecoration: 'underline' }}
					>
						{decoratedText}
					</a>
				)}
				style={{ color: 'white', textDecoration: 'underline' }}
			>
				{parse(temp_text, parseOptions)}
			</Linkify>
		);
	};
	const formatResponse = text => {
		console.log('text', text);
		if (!text) {
			alert('response is empty');
			return;
		}

		let formattedText = text
			.replace(/\n\n/g, '</p><p>') // Paragraphs
			.replace(/\n- /g, '<ul><li>') // Start unordered list item
			.replace(/(\n- .*)/g, '<ul><li>$1</li></ul>') // Wrap list items in <ul>
			.replace(/\n[0-9]+\. /g, '<ol><li>') // Start ordered list item
			.replace(/(\n[0-9]+\. .*)/g, '<ol><li>$1</li></ol>') // Wrap list items in <ol>
			.replace(/\n<\/li>/g, '</li>') // End list item
			.replace(/<\/li>\n<li>/g, '</li><li>') // Continue list item
			.replace(/<\/ul>\n<ul>/g, '') // Remove unnecessary nested <ul>
			.replace(/<\/ol>\n<ol>/g, '') // Remove unnecessary nested <ol>
			.replace(/<\/p><p>\s*<\/p><p>/g, '</p><p>') // Remove empty paragraphs
			.replace(/`([^`]*)`/g, '<pre>$1</pre>') // Inline code blocks
			.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>'); // Links

		// Remove empty code blocks
		formattedText = formattedText.replace(/<pre><\/pre>/g, '');

		return `<div class="response-container"><p>${formattedText?.trim()}</p></div>`;
	};
	const [isAskingChatGPT, setIsAskingChatGPT] = useState(false);
	const cleanPrompt = str => {
		if (!str) return '';

		let cleanedStr = str.replace(/<img[^>]*>/g, '');

		cleanedStr = cleanedStr.replace(/<video[^>]*>.*?<\/video>/g, '');

		cleanedStr = cleanedStr.replace(
			/<a[^>]*>[^<]*(<img[^>]*>|<video[^>]*>.*?<\/video>)[^<]*<\/a>/g,
			''
		);

		cleanedStr = cleanedStr.replace(/<figure[^>]*>.*?<\/figure>/g, '');

		cleanedStr = cleanedStr.replace(/<[^>]*>/g, '');

		return cleanedStr;
	};

	const askChatGPT = async () => {
		setIsAskingChatGPT(true);
		setCommentContent(``);
		setCommentType('comment');
		setShowCommentBox(false);

		setTimeout(async () => {
			let description = task?.description ? cleanPrompt(task.description) : '';
			let comment = '';
			if (comments && comments.length > 0) {
				comment = comments
					?.map(e => {
						return cleanPrompt(e.content);
					})
					.join(',');

				//testing
			}
			let prompt;
			if (task) {
				prompt = `${task?.title} , ${description} , ${comment},
			if response contains code please provide code in triple backticks
			give proper response with text explainations with heading and text
			`;
			}
			if (task.title === '') {
				alert('task title is empty');
				return;
			}

			// const apiKey = `sk-proj-aGyTv3pbDPNIlhkxl0HRT3BlbkFJaK2Akb9cVV6HC4UreDWw`;
			// const apiUrl = "https://api.openai.com/v1/chat/completions";

			const data = {
				model: 'gpt-3.5-turbo',
				messages: [{ role: 'user', content: prompt }]
			};
			// const res = `
			// ${"```"}console.log('Hello')${"```"}

			//  1.point 1
			//  2.point 2
			//  3.ponint 3

			//  # Explanation
			//  In the given JavaScript code snippet, we are using the console.log() function to print the phrase "Hello world" to the console. This is a common way to output messages in JavaScript.
			//    `
			// setCommentContent(`<div class="response-container">${formatResponse(res)}</div>`)
			// setShowCommentBox(true);
			// setIsAskingChatGPT(false);

			const apiKey = `sk-proj-aGyTv3pbDPNIlhkxl0HRT3BlbkFJaK2Akb9cVV6HC4UreDWw`;
			const apiUrl = 'https://api.openai.com/v1/chat/completions';
			try {
				const res = await fetch(apiUrl, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${apiKey}`
					},
					body: JSON.stringify(data)
				});

				const result = await res.json();

				if (result.choices[0]) {
					// setResponseGpt(`<pre>${result.choices[0].message.content}</pre>`);
					// setIsAskingChatGPT(false)
					// setShowResponseBox(true)
					// const marked = new Marked();

					// console.log(marked(formatResponse(result.choices[0].message.content)), 'marked');
					setChatGPTResponse(`${marked(result.choices[0].message.content)}`);
					setCommentContent(`${marked(result.choices[0].message.content)}`);
					setShowCommentBox(true);
					setIsAskingChatGPT(false);
					console.log(apiKey);
				}
			} catch (error) {
				console.error('Error:', error);
			} finally {
				setIsAskingChatGPT(false);
			}
		}, 100);
	};
	const replyChatGpt = async (comment, index) => {
		if (!comment) {
			alert('comment is empty');
			return;
		}
		setIsAskingChatGPT(true);
		setCommentContent(``);
		setCommentType('comment');
		setShowCommentBox(false);
		let boxOpen = false;

		if (showDescriptionBox) {
			setShowDescriptionBox(false);
			boxOpen = true;
		}
		let prevComments = '';

		if (index && index > 0) {
			for (let i = 0; i < index; i++) {
				prevComments += cleanPrompt(comments[i].content) + '\n';
			}
		}

		let commentContent = cleanPrompt(comment);

		setTimeout(async () => {
			let description = task?.description ? cleanPrompt(task.description) : '';
			let prompt;
			if (task) {
				prompt = `
		Understand the task understand the context and give solution to the real problem
	    Context:"	${task?.title} , ${description} , ${prevComments} ", problem : ${commentContent},
		if response contains code please provide code in triple backticks
		give proper response with text explainations with heading and text
		`;
			}

			if (task.title === '') {
				alert('task title is empty');
				return;
			}

			// const apiKey = `sk-proj-aGyTv3pbDPNIlhkxl0HRT3BlbkFJaK2Akb9cVV6HC4UreDWw`;
			// const apiUrl = "https://api.openai.com/v1/chat/completions";

			const data = {
				model: 'gpt-3.5-turbo',
				messages: [{ role: 'user', content: prompt }]
			};
			const apiKey = `sk-proj-aGyTv3pbDPNIlhkxl0HRT3BlbkFJaK2Akb9cVV6HC4UreDWw`;
			const apiUrl = 'https://api.openai.com/v1/chat/completions';
			try {
				const res = await fetch(apiUrl, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${apiKey}`
					},
					body: JSON.stringify(data)
				});

				const result = await res.json();

				if (result.choices[0]) {
					setChatGPTResponse(`${marked(result.choices[0].message.content)}`);
					setCommentContent(`${marked(result.choices[0].message.content)}`);
					setShowCommentBox(true);
					setIsAskingChatGPT(false);

					if (boxOpen) {
						setShowDescriptionBox(true);
					}
				}
			} catch (error) {
				console.error('Error:', error);
			} finally {
				setIsAskingChatGPT(false);
			}
		}, 100);
	};
	const comment_type = searchParams.get('comment_type');
	const comment_text = searchParams.get('comment_text');

	useEffect(() => {
		if (!loading && comment_type && comment_text && comments) {
			console.log('adding comment ->', loading, comment_type, comment_text);
			addComment(comment_type, comment_text);
			searchParams.delete('comment_type');
			searchParams.delete('comment_text');
			setSearchParams(searchParams);
		}
	}, [loading]);

	return (
		<div
			className={`main-content`}
			style={{
				overflow: item_id ? 'unset' : 'scroll',
				height: item_id ? 'fit-content' : '100vh',
				marginTop: '5px',
				padding: '0px'
			}}
		>
			{/* <Backdrop className='backdrop' open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop> */}
			<div className='task-lineage' style={window.innerWidth < 700 ? { padding: '0px' } : {}}>
				<div className='main-lineage'>
					{task && (
						<DisplayBreadcrumbs
							item={task}
							tasks={[task, ...(task?.parents || [])]}
							worksheets={[task?.worksheet]}
							style={{
								marginTop: '0px',
								marginBottom: '0px',
								borderBottom: '1px solid #fff',
								padding: '0px 100px 12px 16px',
								zIndex: 10,
								borderBottomWidth: '1.5px',
								borderBottomStyle: 'solid',
								borderImageSource:
									'linear-gradient(to right, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 85%, rgba(255, 255, 255, 0) 100%)',
								borderImageSlice: 1,
								zIndex: 10
							}}
							showLineageIcon={true}
							users={users}
						/>
					)}
				</div>
				{/* <div style={{ width: '50%' }}>

					<GradientBorder />
				</div> */}
			</div>
			<div className={``}>
				<div className='task-main-info'>
					<h1 className={`task-main-title`}>
						<NavLink
							className={`task-main-title`}
							target='_blank'
							to={`/chopping-interface/${task?.worksheet_id}?item_id=${task?._id}`}
							style={{ color: isTaskAuthoredByOwner ? '#fff' : '#95daff' }}
						>
							{task?.title}
						</NavLink>
					</h1>

					<div className='task-meta-data'>
						<div
							onClick={() => {
								setIsEstimateVisible(!isEstimateVsisble);
							}}
						>
							<TaskMetaDataContainer
								type={'estimate'}
								dataName={'Estimate '}
								dataValue={estimates === 0.125 ? estimates : Number(estimates)?.toFixed(2)}
							/>
						</div>
						<select
							className='ml-1'
							style={{
								fontSize: '14px',
								backgroundColor: '#5a5a5a',
								color: 'white',
								border: 'none',
								display: `${isEstimateVsisble === false ? 'none' : ''}`
							}}
							value={estimates === 0.125 ? estimates : Number(estimates)?.toFixed(2)}
							onChange={updateEstimate}
						>
							<option>{'0'}</option>
							<option>{'0.125'}</option>
							{[...Array(40).keys()]?.map(index => {
								return <option>{((index + 1) * 0.25).toFixed(2)}</option>;
							})}
						</select>
						<div>
							{/* <span style={{ fontWeight: 500 }}>Author</span>: {'@' + activeUserName} */}
							<TaskMetaDataContainer
								type={'author'}
								dataName={'Author '}
								dataValue={'@' + activeUserName}
							/>
						</div>
						<div>
							{/* <span style={{ fontWeight: 500 }}>
								Created on - {task && new Date(task?.created_at).toLocaleDateString('en-GB')}
							</span> */}
							<TaskMetaDataContainer
								type={'created'}
								dataName={'Created On '}
								dataValue={task && new Date(task?.created_at).toLocaleDateString('en-GB')}
							/>
						</div>
						<div
							style={{ cursor: 'pointer' }}
							onClick={() => {
								setChildData({ ...childData, isOpen: !childData.isOpen });
							}}
						>
							{/* <span style={{ fontWeight: 500 }}>Subtasks - {childData.status}</span> */}
							<TaskMetaDataContainer
								type={'subtask'}
								dataName={'Subtask '}
								dataValue={childData?.status}
							/>
						</div>
						{/* <div>
							<span style={{ fontWeight: 500 }}>{task?.description && 'Description'}</span>
							</div> */}
					</div>
					{childData.isOpen && childData.childrens.length > 0 && (
						<div style={{ background: '#000', padding: '10px' }}>
							{childData.childrens?.map(item => {
								return (
									<ChildTasks
										item={item}
										worksheet={childData.worksheet}
										tasks={childData.childrens}
										title={item.title}
										estimate={item.estimate}
										lastComment={item.last_comment_type}
										hasNotification={tasksWithNewComments[item._id]}
										commentCounts={item.comments_count}
										tasksWithNewComments={tasksWithNewComments}
									/>
								);
							})}
						</div>
					)}
					<div className={`task-description-box`}>
						{task && task.description && !showDescriptionBox && (
							<div>{parseText(task.description)}</div>
						)}

						{showDescriptionBox && (
							<Box sx={{ color: 'common.black', width: '100%' }}>
								<Wysiwyg
									task={task}
									value={descriptionContent}
									isComment={false}
									onChange={html => {
										setDescriptionContent(html);
										console.log(html);
									}}
									onSubmit={handleDescriptionSubmit}
									setLoading={setBtnLoading}
									commentButtons={commentButtons}
									onFocus={() => setIsEditorFocused(true)}
									onBlur={() => setIsEditorFocused(false)}
									setCommentType={setCommentType}
									setShowCommentBox={setShowCommentBox}
									worksheetUsers={worksheetUsers}
									type={'description'}
								/>
								<br />
								<LoadingButton
									onClick={e => {
										handleDescriptionSubmit();
									}}
									loading={btnLoading}
									variant='contained'
									className='button__wysiwyg submit__wysiwyg button__primary'
								>
									Add Description
								</LoadingButton>
							</Box>
						)}
						{task && !task.description && !showDescriptionBox && (
							<Button
								variant='contained'
								color='primary'
								onClick={e => {
									setShowDescriptionBox(true);
								}}
								className='button__primary'
							>
								Add Description
							</Button>
						)}
						{task && task.description && !showDescriptionBox && (
							<Button
								variant='contained'
								color='primary'
								onClick={e => {
									setShowDescriptionBox(true);
								}}
								className='button__primary'
								style={{
									marginTop: task.description ? '12px' : '0px'
								}}
							>
								Edit Description
							</Button>
						)}
					</div>
					<Hotkeys
						handleCopyTitle={handleCopyTitle}
						handleCopyWithTick={handleCopyWithTick}
						handleCopyWithLineage={handleCopyWithLineage}
						handleCopyTextSubmission={handleCopyTextSubmission}
						handleCopyClientConversation={handleCopyClientConversation}
						handleCopyAsQuote={handleCopyAsQuote}
						handleCopyWithP1={handleCopyWithP1}
						isSubmission={
							comments &&
							comments?.some(item => item.type === 'submission') &&
							!comments
								?.filter(item => item.type === 'submission')
								[
									comments?.filter(item => item.type === 'submission')?.length - 1
								]?.content?.includes('https://rwlaravel.puneetpugalia.com/file')
						}
						isClientCon={
							comments &&
							comments?.some(item => item.type === 'client_conversation') &&
							!comments
								?.filter(item => item.type === 'client_conversation')
								[
									comments?.filter(item => item.type === 'client_conversation')?.length - 1
								]?.content?.includes('https://rwlaravel.puneetpugalia.com/file')
						}
					/>

					{/* <div
						className='d-flex align-items-center responsive-copy-btn'
						style={{ gap: 10, fontSize: '11px', marginBottom: '12px' }}
					>
						<div
							className='mb-0 d-inline'
							style={{
								cursor: 'pointer',
								backgroundColor: 'gray',
								borderRadius: '5px',
								padding: '5px'
							}}
							onClick={handleCopyTitle}
						>
							Copy title
						</div>
						<div
							className='mb-0 d-inline'
							style={{
								cursor: 'pointer',
								backgroundColor: 'gray',
								borderRadius: '5px',
								padding: '5px'
							}}
							onClick={handleCopyWithTick}
						>
							Copy with ✅
						</div>
						<div
							className='mb-0 d-inline'
							style={{
								cursor: 'pointer',
								backgroundColor: 'gray',
								borderRadius: '5px',
								padding: '5px'
							}}
							onClick={handleCopyWithLineage}
						>
							Copy with lineage
						</div>
						{comments &&
							comments?.some(item => item.type === 'submission') &&
							!comments
								?.filter(item => item.type === 'submission')
							[comments?.filter(item => item.type === 'submission')?.length - 1]?.content?.includes(
								'https://rwlaravel.puneetpugalia.com/file'
							) ? (
							<div
								className='mb-0 d-inline'
								style={{
									cursor: 'pointer',
									backgroundColor: '#54A349',
									borderRadius: '5px',
									padding: '5px'
								}}
								onClick={handleCopyTextSubmission}
							>
								Copy Text Submission
							</div>
						) : (
							''
						)}

						{comments &&
							comments?.some(item => item.type === 'client_conversation') &&
							!comments
								?.filter(item => item.type === 'client_conversation')
							[
								comments?.filter(item => item.type === 'client_conversation')?.length - 1
							]?.content?.includes('https://rwlaravel.puneetpugalia.com/file') ? (
							<div
								className='mb-0 d-inline'
								style={{
									cursor: 'pointer',
									backgroundColor: '#54A349',
									borderRadius: '5px',
									padding: '5px'
								}}
								onClick={handleCopyClientConversation}
							>
								Copy Client Conv
							</div>
						) : (
							''
						)}
						<div
							className='mb-0 d-inline'
							style={{
								cursor: 'pointer',
								backgroundColor: 'gray',
								borderRadius: '5px',
								padding: '5px'
							}}
							onClick={handleCopyAsQuote}
						>
							Copy as quote
						</div>
						<div
							className='mb-0 d-inline'
							style={{
								cursor: 'pointer',
								backgroundColor: 'gray',
								borderRadius: '5px',
								padding: '5px'
							}}
							onClick={handleCopyWithP1}
						>
							✅ Copy with P1
						</div>
					</div> */}
				</div>

				<Modal
					open={openDeliveryModal}
					onClose={() => setOpenDeliveryModal(false)}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
					className='modal_root'
				>
					<Box className={`modal_container fade_in_bottom`}>
						<Box className={`modal_body`}>
							<div
								className='my-4'
								style={{
									display: 'flex',
									gap: 10,
									justifyContent: 'space-between',
									cursor: 'pointer'
								}}
							>
								<div>Add to Deliveries</div>
								<div className={`fa_cross_icon`}>
									<FaTimes size={10} onClick={() => setOpenDeliveryModal(false)} />
								</div>
							</div>
							<div style={{ margin: '15px 0 5px', width: '41%' }}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										width: '100%',
										alignItems: 'center',
										flexDirection: window.innerWidth <= 600 ? 'column' : 'row'
									}}
								>
									<div>Scheduled Date</div>
									<div className='mt-1 sm:mt-0 sm:col-span-2'>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DesktopDatePicker
												inputFormat='DD/MM/YYYY'
												value={scheduledDate}
												onChange={e => setScheduledDate(e.$d)}
												renderInput={params => (
													<TextField
														{...params}
														className={classes.TextField}
														size='small'
														sx={{
															width: 181,
															fontSize: 16
														}}
													/>
												)}
											/>
										</LocalizationProvider>
									</div>
								</div>
							</div>
							<div
								className='d-flex justify-content-end sm:justify-content-start'
								style={{ width: '41%', marginBottom: '15px' }}
							>
								<div className='col-auto p-0'>
									<span
										className={`${styles.presetFooterComment} font-weight-bold`}
										style={{ color: 'white', fontWeight: 'bold', opacity: '1' }}
										onClick={tomorrowDate}
									>
										Tomorrow
									</span>
								</div>
								<div className='col-auto p-0 ml-4'>
									<span
										className={`${styles.presetFooterComment} font-weight-bold`}
										style={{ color: 'white', fontWeight: 'bold', opacity: '1' }}
										onClick={nextMonday}
									>
										Monday
									</span>
								</div>
							</div>
							<Wysiwyg
								task={task}
								value={deliveryContent}
								isComment={false}
								onChange={html => setDeliveryContent(html)}
								onSubmit={() => {
									handleAddToDelivery();
									addComment('approve', 'Looks good');
								}}
								setLoading={setBtnLoading}
								commentButtons={commentButtons}
								onFocus={() => setIsEditorFocused(true)}
								onBlur={() => setIsEditorFocused(false)}
								setCommentType={setCommentType}
								setShowCommentBox={setShowCommentBox}
								worksheetUsers={worksheetUsers}
							/>
							<LoadingButton
								loading={btnLoading}
								onClick={() => {
									handleAddToDelivery();
									addComment('approve', 'Looks good');
								}}
								loadingIndicator='Uploading…'
								variant='contained'
								className='mt-3'
							>
								Submit
							</LoadingButton>
							<LoadingButton
								variant='contained'
								color='error'
								className='ml-3 mt-3'
								onClick={() => setOpenDeliveryModal(false)}
							>
								Cancel
							</LoadingButton>
						</Box>
					</Box>
				</Modal>

				{comments &&
					comments?.map((comment, index) => {
						// console.log(comment, "comment")
						const lastCommentWithPresetMsg = [...comments]
							.reverse()
							.find(
								item =>
									(item.type === 'submission' && item.user_id !== user._id) ||
									(item.type === 'approve' && item.user_id !== user._id) ||
									(item.type === 'escalation' && item.user_id !== user._id) ||
									(item.type === 'client_conversation' && item.user_id !== user._id)
							);

						return (
							<React.Fragment key={index}>
								{(index === comments.length - 1 && shouldScrollToComment) || hyperlinkedComment ? (
									<span style={{ visibility: 'hidden' }} ref={lastCommentRef}></span>
								) : (
									''
								)}
								<Comment
									task={task}
									key={index}
									comment={comment}
									printDate={printDate}
									btnLoading={btnLoading}
									editComment={editComment}
									editCommentIds={editCommentIds}
									setEditTrue={setEditTrue}
									setEditFalse={setEditFalse}
									deleteComment={deleteComment}
									parseText={parseText}
									lastCommentRef={lastCommentRef}
									notificationCommentRef={notificationCommentRef}
									comment_id={comment_id}
									shouldScrollToComment={shouldScrollToComment}
									onSubmit={handlePresetCommentSubmit}
									handleDeliveryModalOpen={handleDeliveryModalOpen}
									presetMessages={comment === lastCommentWithPresetMsg ? presetMessages : []}
									setDeliveryContent={setDeliveryContent}
									setTaskCommentId={setTaskCommentId}
									taskCommentId={taskCommentId}
									handleAddToDelivery={handleAddToDelivery}
									handleUndoDelivery={handleUndoDelivery}
									users={users}
									worksheetUsers={worksheetUsers}
									setIsEditorFocused={setIsEditorFocused}
									index={index}
									replyChatGpt={replyChatGpt}
									newCommentId={newCommentId}
								/>
							</React.Fragment>
						);
					})}

				{isAskingChatGPT && (
					<>
						<div
							style={{
								background: '#2f2f2f',
								color: '#fff',
								padding: '10px',
								borderRadius: '10px',
								height: '100px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								marginTop: '10px'
							}}
						>
							<h1 style={{ color: '#c5c5c5' }}>Asking ChatGPT Please Wait....</h1>
						</div>
					</>
				)}
				{loading ? (
					<div className='comment-spinner'>
						<Backdrop className={classes.backDrop} open={loading}>
							<CircularProgress color='inherit' />
						</Backdrop>
					</div>
				) : (
					''
				)}

				{/* only show comment buttons after comments loaded*/}
				{!loading && comments && (
					<div className='text-editor-container'>
						<div className='responsive-comment-actions'>
							<div className='d-flex flex-wrap text-nowrap row mx-0'>
								{/* {
                        !showCommentBox && 
                            <div style={{ cursor: 'pointer' }} onClick={(e) => {setShowCommentBox(true);}} className="d-flex mx-3"> 
                                <div>
                                    <CommentIcon type={'comment'} className="mx-2" style={{ color: 'white' }}/>
                                </div>
                                <div className="mx-1 text-normal">
                                    Add a comment
                                </div>
                            </div>
                    }   */}
								{commentButtons.map((comment, index) => {
									return (
										<React.Fragment key={index}>
											{((comment.for_leader && leaderMenu) || !comment.for_leader) && (
												<div
													className='px-0 mb-4 mb-md-2 mx-lg-2 mx-xl-0  col-3 col-lg-2 d-flex flex-column flex-sm-row align-items-center comment_icons__parent'
													style={{ cursor: 'pointer' }}
													onClick={e => {
														setShowCommentBox(true);
														setCommentType(comment.key);
														localStorage.setItem(`task-${taskId}-comment-type`, comment.key);
													}}
												>
													<div
														style={{
															fontSize: '20px',

															opacity: !showCommentBox
																? '1'
																: commentType === comment.key
																? '1'
																: '0.5'
														}}
														className='mx-2'
													>
														{comment.icon}
													</div>
													<div
														style={{
															opacity: !showCommentBox
																? '1'
																: commentType === comment.key
																? '1'
																: '0.5'
														}}
														className={`text-normal ${styles.responsiveText}`}
													>
														{comment.label}
													</div>
												</div>
											)}
										</React.Fragment>
									);
								})}
								<div
									className='px-0 mb-4 mb-md-2 mx-lg-2 mx-xl-0  col-3 col-lg-2 d-flex flex-column flex-sm-row align-items-center'
									style={{
										cursor: 'pointer',
										marginTop: window.width > 1200 ? '20px' : '0px',
										opacity: commentType === '' ? '1' : '0.5'
									}}
									onClick={e => {
										askChatGPT();
									}}
								>
									<div
										style={{
											fontSize: '20px',
											marginBottom: window.width > 1200 ? '8px' : '4px',
											opacity: '1'
										}}
										className='mx-2'
									>
										<FaRobot />
									</div>
									<div className={`text-normal ${styles.responsiveText}`}>Ask ChatGPT</div>
								</div>
							</div>
						</div>
						<div style={{ background: '#24272D' }} className='responsive-comment-add'>
							{(showCommentBox || commentContent) && (
								<Box>
									<Wysiwyg
										task={task}
										defaultValue={defaultValueForEditor}
										value={commentContent}
										chatGPTResponse={chatGPTResponse}
										setChatGPTResponse={setChatGPTResponse}
										isComment={true}
										worksheetUsers={worksheetUsers}
										onChange={html => {
											setCommentContent(html);
											localStorage.setItem(`task-${taskId}-comment`, html);
										}}
										showCustomMessage={true}
										setShowCommentBox={setShowCommentBox}
										setCommentType={setCommentType}
										customMessageType={commentType}
										onSubmit={handleCommentSubmit}
										setLoading={setBtnLoading}
										setCommentContent={setCommentContent}
										commentButtons={commentButtons}
										type={'commentSetter'}
										onFocus={() => setIsEditorFocused(true)}
										onBlur={() => setIsEditorFocused(false)}
										btnLoading={btnLoading}
									/>
								</Box>
							)}
						</div>
					</div>
				)}
			</div>
			<Modal
				open={isModalOpened}
				onClose={handleCloseTaskCommentModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className='modal_root'
			>
				<Box
					className={`modal_container ${
						openTaskCommentModal ? 'fade_int_bottom' : 'fade_out_bottom'
					}`}
				>
					<Box className={`modal_header`}>{commentType}</Box>
					<Box className={`modal_body`}>
						<Wysiwyg
							task={task}
							worksheetUsers={worksheetUsers}
							onChange={html => setCommentContent(html)}
							showCustomMessage={true}
							customMessageType={commentType}
							onSubmit={handleCommentSubmit}
							setLoading={setBtnLoading}
							commentButtons={commentButtons}
							onFocus={() => setIsEditorFocused(true)}
							onBlur={() => setIsEditorFocused(false)}
						/>

						<LoadingButton
							onClick={() => {
								handleCommentSubmit();
							}}
							loading={btnLoading}
							loadingIndicator='Uploading…'
							variant='contained'
						>
							Save
						</LoadingButton>

						{/* <button onClick={() => { handleCommentSubmit() }}>Save</button> */}
					</Box>
				</Box>
			</Modal>
		</div>
	);
};

const Comment = ({
	task,
	comment,
	printDate,
	editComment,
	editCommentIds,
	setEditTrue,
	setEditFalse,
	deleteComment,
	parseText,
	onSubmit,
	presetMessages,
	notificationCommentRef,
	comment_id,
	handleDeliveryModalOpen,
	handleAddToDelivery,
	setTaskCommentId,
	setDeliveryContent,
	handleUndoDelivery,
	taskCommentId,
	users,
	worksheetUsers,
	setIsEditorFocused,
	index,
	replyChatGpt,
	newCommentId
}) => {
	const noRef = useRef(null);

	const [content, setContent] = useState('');
	const [btnLoading, setBtnLoading] = useState(false);

	const ownerId = users?.find(_user => _user.role === 'owner')?._id;
	const isTaskAuthoredByOwner = ownerId === task?.user_id;

	const handleCommentEdit = content => {
		let temp_comment = comment;
		temp_comment.content = content;
		editComment(temp_comment);
	};

	const handleCommentLinkCopy = () => {
		const url = `${process.env.REACT_APP_LARAVEL_API_URL}/comment/${comment._id}/mark-as-public`;
		axios.post(url).then(res => {});

		navigator.clipboard
			.writeText([`https://redwing.pages.dev/comment/${comment?._id}`])
			.then(() => {
				setTimeout(() => toastr.success(`Comment link copied to clipboard.`), 300);
			})
			.catch(error => console.error('Error copying text: ', error));
	};

	const isDateInPast = (targetDate, nDays = 1) => {
		const today = moment();
		const targetMoment = moment(targetDate);
		return today.diff(targetMoment, 'days') > nDays;
	};

	// Preset comments for comment footer
	const CommentChips = () => {
		return presetMessages ? (
			<div className={`${presetMessages?.length > 0 ? 'quick-reply' : ''}`}>
				{presetMessages
					?.filter(item => {
						if (comment.type === 'submission' || comment.type === 'approve') {
							return item.category === 'approve';
						} else if (comment.type === 'client_conversation') {
							if (comment.user_id === ownerId && isDateInPast(comment.updated_at, 3)) {
								return item.category === 'followup';
							}
							return item.category === 'client_conversation';
						} else if (comment.type === 'escalation') {
							return item.category === 'de-escalation';
						}
						return false;
					})
					?.map((value, index) => {
						const cleanComment = value.message
							.replace(/<\/?[^>]+(>|$)/g, '')
							.replace(/&amp;|&&nbsp/g, '&')
							.replace(/&nbsp;/g, '')
							.replace(/&lt;/g, '<')
							.replace(/&gt;/g, '>');

						return cleanComment.length < 50 ? (
							<div key={index} className={`col-auto quick-reply-btn`}>
								<span
									className={`quick-reply-text ${styles.presetOnMobile}`}
									// sx={{'& .MuiChip-label': {padding: '0px'}}}
									key={index}
									onClick={() => {
										onSubmit(value.category, value.message);
									}}
								>
									{cleanComment}
								</span>
							</div>
						) : (
							''
						);
					})}
			</div>
		) : (
			<></>
		);
	};
	const printMobileDates = rawdate => {
		if (rawdate) {
			var date = moment(rawdate);
			var now = moment();
			if (now.diff(date, 'hours') < 24) {
				// let forMobile = date.fromNow();
				// forMobile = forMobile.replace(" hours ago", "h");
				// forMobile = forMobile.replace("an hour ago", "1h");
				// forMobile = forMobile.replace(" minutes ago", "m");
				// forMobile = forMobile.replace("a minute ago", "1m");
				// forMobile = forMobile.replace(" days ago", "d");
				// forMobile = forMobile.replace("a day ago", "1d");
				return window.innerWidth <= 600 && mobileTimeFormatter(rawdate) + ' ago';
			} else {
				return date.format('MMM Do');
			}
		}
		return '';
	};

	return (
		<div
			ref={comment?._id === comment_id ? notificationCommentRef : noRef}
			className={`user-comment-body ${
				comment?.temp_id === newCommentId ? 'new-comment-animate' : ''
			}`}
			style={{ border: comment?.is_delivered ? '2px solid green' : '' }}
		>
			<div style={{ marginBottom: '-8px', width: '100%' }}>
				<GradientBorder />
			</div>
			<div className={`user-comment-header`}>
				{/* <div className='col-md-8 p-0 d-flex align-items-center'>
					<CustomAvatar
						avatar={comment?.user?.avatar}
						alt='profile'
						name={comment?.user?.name}
						style={{
							width: '20px',
							height: '20px',
							fontSize: '10px',
							fontWeight: 'bold',
							borderRadius: '100%',
							marginRight: '10px'
						}}
					/>
					{comment?.user?.name} <CommentIcon type={comment.type} className='mx-2' />
				</div> */}
				<div className='comment-user'>
					<div className='user-action-container'>
						<AvatarWithAction
							avatar={comment?.user?.avatar}
							name={comment?.user?.name}
							actionType={comment?.type}
						/>
					</div>
					<span className='comment-user-name'>{comment?.user?.name}</span>
				</div>

				<div className='col-md-4 p-0 d-flex align-items-center justify-content-end'>
					<div>
						{' '}
						{window.innerWidth <= 600
							? printMobileDates(comment.created_at)
							: printDate(comment.created_at)}
					</div>
					<div>
						{user._id === comment.user_id && (
							<CustomMenu
								setEditTrue={setEditTrue}
								comment={comment}
								deleteComment={deleteComment}
							/>
						)}
					</div>
					<div>
						<button className='ai-btn' onClick={() => replyChatGpt(comment?.content, index)}>
							{' '}
							<FaRobot /> <span>AI REPLY</span>
						</button>
					</div>
					<AiOutlineLink
						className='mb-0 d-inline'
						style={{ cursor: 'pointer', marginLeft: 10 }}
						onClick={() => {
							navigator.clipboard
								.writeText(
									window.location.origin +
										`/chopping-interface/${task?.worksheet_id}?item_id=${comment?.task_id}&comment_id=${comment._id}`
								)
								.then(() => {
									setTimeout(() => toastr.success(`Comment link copied to clipboard.`), 300);
								})
								.catch(error => console.error('Error copying text: ', error));
						}}
					/>
					<AiOutlineCopy
						className='mb-0 d-inline'
						style={{ cursor: 'pointer', marginLeft: 10 }}
						onClick={() => {
							navigator.clipboard
								.writeText(
									comment?.content
										.replaceAll('<li>', '\n')
										.replaceAll('</h1>', '\n')
										.replaceAll('<br>', '\n')
										.replace(/<\/?[^>]+(>|$)/g, '')
										.replace(/&amp;|&&nbsp/g, '&')
										.replace(/&nbsp;/g, '\n')
										.replace(/&lt;/g, '<')
										.replace(/&gt;/g, '>')
										.trim()
								)
								.then(() => {
									setTimeout(() => toastr.success(`Comment content copied to clipboard.`), 300);
								})
								.catch(error => console.error('Error copying text: ', error));
						}}
					/>
					<HiClipboardCopy
						className='mb-0 d-inline'
						style={{ cursor: 'pointer', marginLeft: 10 }}
						onClick={handleCommentLinkCopy}
					/>
				</div>
			</div>
			<div style={{ paddingTo: '0px' }} className={` gpt-response-styling`}>
				{comment?.content && (
					<div style={{ lineHeight: '19px' }}>
						{!editCommentIds.includes(comment._id) && parseText(comment.content)}
						{editCommentIds.includes(comment._id) && (
							<div>
								<Wysiwyg
									task={task}
									worksheetUsers={worksheetUsers}
									value={comment.content}
									onChange={html => setContent(html)}
									onSubmit={handleCommentEdit}
									setLoading={setBtnLoading}
									onFocus={() => setIsEditorFocused(true)}
									onBlur={() => setIsEditorFocused(false)}
								/>
								<LoadingButton
									onClick={() => {
										handleCommentEdit(content);
									}}
									loading={btnLoading}
									variant='contained'
									className='mt-3 button__wysiwyg submit__wysiwyg'
								>
									Save
								</LoadingButton>
								<LoadingButton
									onClick={() => {
										setEditFalse(comment);
									}}
									variant='contained'
									loading={btnLoading}
									color='error'
									className='ml-3 mt-3 button__wysiwyg cancel__wysiwyg'
								>
									Cancel
								</LoadingButton>
							</div>
						)}
					</div>
				)}
			</div>
			{presetMessages?.length || comment.type === 'submission' ? (
				<div className={`${styles.comment_footer} row d-flex align-items-center`}>
					<CommentChips />

					{!editCommentIds.includes(comment._id) &&
						comment.type === 'submission' &&
						user?.role !== 'owner' && (
							<div className={`col-auto p-0 ml-auto ${styles.onMobileDeliveryLinks}`}>
								{isTaskAuthoredByOwner ? null : (
									<span className='internal-task-tag'>Internal Task</span>
								)}
								<span
									className={`${styles.presetFooterComment} ${styles.DeliveryBtnOnMobile} font-weight-bold`}
									style={{ color: '#fff', fontWeight: 'bold', opacity: '1' }}
								>
									Delivery -
								</span>
								<span
									className={`${styles.presetFooterComment} ${styles.DeliveryBtnOnMobile} font-weight-bold`}
									style={{ color: 'rgb(84, 163, 73)', fontWeight: 'bold', opacity: '1' }}
									onClick={() => {
										handleAddToDelivery('exact', comment._id, comment.content);
										onSubmit('approve', 'Looks good');
										// handleDeliveryModalOpen(comment.content, comment._id)
									}}
								>
									Exact
								</span>
								<span
									className={`${styles.presetFooterComment} ${styles.DeliveryBtnOnMobile} font-weight-bold`}
									style={{ color: 'rgb(84, 163, 73)', fontWeight: 'bold', opacity: '1' }}
									onClick={() => {
										handleAddToDelivery('empty', comment._id, comment.content);
										onSubmit('approve', 'Looks good');
										// handleDeliveryModalOpen(comment.content, comment._id)
									}}
								>
									Empty
								</span>
								<span
									className={`${styles.presetFooterComment} ${styles.DeliveryBtnOnMobile} font-weight-bold`}
									style={{ color: 'rgb(84, 163, 73)', fontWeight: 'bold', opacity: '1' }}
									onClick={() => {
										handleDeliveryModalOpen(comment.content, comment._id);
									}}
								>
									Edited
								</span>

								{comment?.is_delivered ? (
									<>
										<span
											className={`${styles.presetFooterComment} ${styles.DeliveryBtnOnMobile} font-weight-bold`}
											style={{ color: 'red', fontWeight: 'bold', opacity: '1' }}
											onClick={() => {
												console.log(comment);
												handleUndoDelivery(comment._id);
											}}
										>
											Undo Delivery
										</span>
									</>
								) : (
									''
								)}
							</div>
						)}
				</div>
			) : (
				''
			)}
		</div>
		// </div>
	);
};

const useCustomMenuStyles = makeStyles(theme => ({
	root: {
		marginTop: '-50px',
		overflow: 'auto',
		'& .MuiMenu-list': {
			color: 'white'
		},
		'& .MuiListItemText-root': {
			margin: '0px',
			padding: '0px'
		},
		'& .MuiListItemIcon-root': {
			minWidth: '18px !important',
			marginRight: '1rem',
			padding: '0px'
		},
		'& .MuiPaper-root': {
			backgroundColor: 'black !important',
			width: '150px'
		},
		shortcut: {
			color: 'white',
			paddingLeft: '2rem'
		}
	}
}));

const CustomMenu = ({ setEditTrue, comment, deleteComment }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const classes = useCustomMenuStyles();
	const open = Boolean(anchorEl);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			{/* actions menu trigger button*/}

			<Button
				id='basic-button'
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				style={{ padding: 0, margin: 0, minWidth: 'auto' }}
			>
				<ThreeDotsVertical style={{ color: 'white', height: '20px' }} />
			</Button>

			{/* actions menu */}

			<Menu
				id='basic-menu'
				anchorEl={anchorEl}
				open={!!open}
				// open={true}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button'
				}}
				className={`${classes.root}`}
			>
				<MenuItem
					onClick={e => {
						e.preventDefault();
						handleClose();
						setEditTrue(comment);
					}}
				>
					<ListItemText>Edit</ListItemText>
				</MenuItem>
				<MenuItem
					onClick={e => {
						e.preventDefault();
						handleClose();
						deleteComment(comment);
					}}
				>
					<ListItemText>Delete</ListItemText>
				</MenuItem>
			</Menu>
		</>
	);
};

export default TaskDetails;
